.total-card {
  @extend .card;
  height: auto;

  &__wrap {
    position: relative;
    padding: 11px 16px 11px 60px;
    border-bottom: 1px solid $grey-10;
  }

  &__wrap--link {
    padding-right: 64px;
  }

  &__link {
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 20px;
    padding: 4px;
    color: $grey-70;
    cursor: pointer;
    transition: 300ms all;
    transform: translateY(-50%);

    &:hover {
      color: $grey-120;
    }

    &--button {
      appearance: none;
      background-color: transparent;
      border: none;
    }
  }

  &__link-icon {
    width: 16px;
    height: 16px;
  }

  &__figure {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  &__icon {
    width: 36px;
    height: 36px;
  }

  &__heading {
    @extend .font-size-30;
    line-height: 36px;
    color: $grey-135;
    font-weight: 500;
  }

  &__body {
    padding: 13px 16px;
  }

  &__loading {
    display: inline-block;
  }

  &__count {
    @extend .font-size-80;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: $grey-135;
  }
}