.site-option {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; 
  box-shadow: 0 4px 6px -1px rgb(235, 233, 233), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 3px solid transparent;
  border-radius: 8px;
  width: 350px;
  padding: 15px;
  background: $white;
  cursor: pointer;

  &--selected {
    border-color: $purple-60;
  }

  &__icon {
    flex: 0.2;
    margin: auto;
    width: 20px;
    height: 20px;
    color: $purple-60;
  }

  &__label {
    flex: 1;
    margin: 15px;
    text-align: left;
  }

  &__status {
    @extend .font-size-5;
    position: absolute; 
    top: 5px;
    right: 5px;
    border-radius: 2px;
    min-width: max-content;
    padding: 0 4px;
    background-color: $purple-60-overlay-8;
    font-weight: 600;
    text-transform: uppercase;
    color: $purple-60;
  
    &--inprogress {
      background-color: $orange-60-transparent-8;
      color: $orange-60;
    }
 
    &--completed {
      background-color: $green-80-transparent-8;
      color: $green-80;
    }
  }
}
