.subnet {

  &__form-row {
    margin-top: 20px;
    margin-bottom: 20px;

    &--extra-margin {
      margin-top: 40px;
    }
  }

  &__col--extra-margin {
    margin-bottom: 15px;
  }

  &__heading {
    @extend .font-size-40;
    margin-bottom: 8px;
  }

  &__excluded-address-range {
    width: 100%;
  }

  &__range-to-container {
    justify-content: center;
    display: flex;
  }

  &__range-to {
    @extend .font-size-20;
    margin-top: 30px;
  }

  &__add-exluded-address-range {
    width: 100%;
  }

  &__add-dns-server {
    margin-top: 15px;
    width: 100%;
  }
}
