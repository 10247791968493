.filters {

  &__trigger {
    vertical-align: middle;
  }

  &__overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(29, 11, 36, 0.1);
    transition: 300ms all;
    z-index: 4;

    &[data-state='open'] {
      animation: fadeIn 0.15s ease forwards;
    }

    &[data-state='closed'] {
      animation: fadeOut 0.15s ease forwards;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    background-color: $white;
    z-index: 5;

    &[data-state='open'] {
      animation: slideIn 0.25s ease forwards;
    }

    &[data-state='closed'] {
      animation: slideOut 0.25s ease forwards;
    }
  }

  &__heading {
    display: flex;
    padding: 13px 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-10;
  }

  &__title {
    @extend .font-size-40;
    font-weight: 500;
    color: $grey-80;
  }

  &__close-button {
    padding: 0px;
    appearance: none;
    border: none;
    background-color: transparent;
    color: $grey-80;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      color: $purple-60;
    }
  }

  &__close {
    width: 9px;
    height: 9px;
  }

  &__body {
    position: relative;
    height: calc(100% - 51px);
    padding: 0 20px 105px;
    overflow: auto;
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    margin-top: 24px;
  }

  &__label {
    display: block;
    margin-bottom: 8px;
  }

  &__foot {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: $white;
    border-top: 1px solid $grey-10;
    text-align: right;
  }

  &__button+&__button {
    margin-left: 12px;
  }

  &__outer {
    display: inline-block;
    width: 100%;
    vertical-align: middle;

    &--requests {
      width: calc(100% - 142px);
    }

    &--incidents {
      width: calc(100% - 140px);
    }

    &.inventory__device-filters {
      .filters__inner:after {
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(250, 249, 250, 0) 100%);
      }
    }
  }

  &__inner {
    position: relative;
    display: inline-block;
    width: calc(100% - 104px);
    margin-left: 16px;
    vertical-align: middle;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 32px;
      background: linear-gradient(270deg, #FAF9FA 0%, rgba(250, 249, 250, 0) 100%);
    }
  }

  &__list {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    white-space: nowrap;
    vertical-align: middle;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__remove-item {
    display: inline-block;
    margin-right: 4px;

    &:last-child {
      margin-right: 24px;
    }
  }

  &__remove {
    position: relative;
    padding: 7px 30px 7px 12px;
    appearance: none;
    background-color: $grey-5;
    border-radius: 4px;
    border: 1px solid $grey-10;
    font-size: 0;
    color: $grey-80;
    cursor: pointer;

    &:hover {

      .filters__remove-icon {
        color: $purple-60;
      }
    }
  }

  &__remove-title,
  &__remove-label {
    display: inline-block;
    @extend .font-size-20;
  }

  &__remove-title {
    font-weight: 600;
  }

  &__remove-title+&__remove-label {
    margin-left: 4px;
  }

  &__remove-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 7px;
    height: 7px;
    transform: translateY(-50%);
    transition: 300ms all;
  }
}
