.sign-up {

  &__heading {
    @extend .font-size-60;
  }

  &__subheading {
    margin-top: 10px;
    @extend .font-size-30;
    color: $grey-60;
  }

  &__error {
    margin-top: 20px;
  }

  &__figure {
    margin: 20px auto 0;
    padding: 16px;
    background-color: $off-white;
    text-align: center;
  }

  &__qr {
    display: inline-block;
    margin: 0 auto;
  }

  &__digits {
    margin-top: 20px;
  }

  &__wrap {
    position: relative;
    margin: 20px 0 0;
    padding: 12px 44px 12px 12px;
    background-color: $grey-5;
    border: 1px solid $grey-10;
    border-radius: 5px;
    @extend .font-size-30;
    color: $grey-70;
  }

  &__copy {
    position: absolute;
    top: 50%;
    right: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    appearance: none;
    border: 0;
    background-color: rgba(0,0,0,0);
    color: $grey-30;
    transform: translateY(-50%);
    transition: 300ms all;
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: $grey-60;
    }

  }

  &__copy-icon {
    width: 20px;
    height: 20px;
  }

  &__footer {
    margin-top: 40px;
    text-align: right;
  }

  &__button + &__button {
    margin-left: 12px;
  }

  &__button--enable {
    min-width: 73px;
  }
}