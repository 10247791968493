.tabs {

  &__list {
    padding: 0 30px;
  }

  &__trigger {
    position: relative;
    display: inline-block;
    margin-right: 16px;
    appearance: none;
    border: 0;
    padding: 9px 0;
    background-color: rgba(0,0,0,0);
    @extend .font-size-30;
    font-weight: 500;
    color: $grey-95;
    cursor: pointer;
    transition: 300ms all;

    &:after {
      content: '';
      position: absolute;
      right: 100%;
      bottom: -1px;
      left: 0;
      width: 0;
      border-bottom: 2px solid $purple-60;
      transition: 300ms all;
    }

    &[data-state="active"],
    &:hover {
      color: $purple-60;

      &:after {
        right: 0;
        width: 100%;
      }
    }

    &[data-disabled] {
      cursor: initial;
      color: $grey-30;

      &:after {
        border-bottom-color: $grey-30;
      } 
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}