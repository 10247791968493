.order-success {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  gap: 40px;

  &__heading {
    @extend .font-size-60;
    color: $grey-135;
  }
}
