.user-modal {
  &__heading {
    @extend .font-size-50;
    font-weight: 500;
    color: $grey-135;
    margin-bottom: 30px;
  }

  &__form-row {
    margin-top: 20px;
    margin-bottom: 20px;

    &--extra-margin {
      margin-top: 40px;
    }
  }

  &__actions {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &--cancel {
      margin-right: 12px;
    }
  }
}
