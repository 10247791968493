.resolution {

  &__heading {
    @extend .font-size-50;
    color: $grey-135;
  }

  &__subheading {
    margin: 8px 0 0;
    @extend .font-size-30;
    color: $grey-60;
  }

    &__loading {
    display: flex;
    min-height: 160px;
    width: 100%;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }

  &__required,
  &__message {
    margin: 20px 0 0;
  }

  &__asterisk,
  &__info {
    display: inline-block;
    vertical-align: top;
    @extend .font-size-30;
    color: $grey-60;
  }

  &__asterisk {
    margin-right: 6px;
    color: $orange-60;
  }

  &__row {
    margin-top: 20px;
  }

  &__col {
    margin-top: 20px;

    &--no-margin {
      margin-top: 0;
    }
  }

  &__footer {
    margin: 40px 0 0;
    text-align: right;
  }

  &__button + &__button {
    margin-left: 12px;
  }

  &__button--submit {
    min-width: 34px;
  }
}