.help-text {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  color: $green-60;
  text-align: left;

  a {
    color: $green-60;

    &:after {
      background-color: $green-60;
    }

    &:hover {
      color: $green-80;

      &:after {
        background-color: $green-80;
      }
    }
  }

  &--info {
    padding-left: 0;
    color: $grey-70;

    a {
      color: $grey-70;

      &:after {
        background-color: $grey-70;
      }

      &:hover {
        color: $grey-90;

        &:after {
          background-color: $grey-90;
        }
      }
    }
  }

  &--error {
    color: $maroon-60;

    a {
      color: $maroon-60;

      &:after {
        background-color: $maroon-60;
      }

      &:hover {
        color: $maroon-80;

        &:after {
          background-color: $maroon-80;
        }
      }
    }
  }

  &--warning {
    color: $orange-60;

    a {
      color: $orange-60;

      &:after {
        background-color: $orange-60;
      }

      &:hover {
        color: $orange-80;

        &:after {
          background-color: $orange-80;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
  }

  &__message {
    @extend .font-size-20;

    a {
      @extend .link;
      font-size: inherit;
    }
  }

}