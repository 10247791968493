.create-technical-data-request-modal {
  &__content .dialog__inner {
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    gap: 15px;
    min-height: 250px;
  }

  &__heading {
    @extend .font-size-50;
    margin-bottom: 30px;
    font-weight: 500;
    color: $grey-135;
  }

  &__actions {
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }
}
