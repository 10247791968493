.files-group {
  text-align: left;

  &__label-wrap + &__field-wrap,
  &__content-wrap + &__field-wrap {
    margin-top: 8px;
  }

  &__label-wrap + &__content-wrap {
    margin-top: 5px;
  }

  &__field-wrap {
    position: relative;
  }

  &__dropzone {
    min-height: 74px;
    width: 100%;
    padding: 16px 24px;
    text-align: center;
    border-radius: 8px;
    border: 1px dashed $grey-10;
    color: $grey-70;
    transition: 300ms all;

    &--focus {
      background-color: $off-white;
      border-color: $purple-60;
      border-style: solid;
      color: $purple-60;
    }
  }

  &__message,
  &__formats {
    display: block;
    font-weight: 400;
  }

  &__message {
    @extend .font-size-30;
  }

  &__click {
    position: relative;
    display: inline-block;
    color: $purple-60;
    font-weight: 600;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      right: 50%;
      bottom: 0;
      left: 50%;
      height: 1px;
      width: 0;
      background-color: $purple-60;
      transition: 300ms all;
    }

    &:hover:after {
      right: 0;
      left: 0;
      width: 100%;
    }
  }

  &__formats {
    margin-top: 4px;
    @extend .font-size-20;
  }

  &__list {
    margin: 14px 0 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-top: 14px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__card {
    position: relative;
    padding: 8px 40px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $grey-10;
  }

  &__download {
    position: absolute;
    top: 50%;
    left: 14px;
    width: 14px;
    height: 14px;
    color: $grey-70;
    transform: translateY(-50%);
  }

  &__name {
    position: relative;
    display: inline-block;
    @extend .font-size-30;
    font-weight: 500;
    color: $purple-60;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: $purple-60;
    }
  }

  &__size {
    display: block;
    margin-top: 4px;
    @extend .font-size-20;
    color: $grey-70;
  }

  &__button {
    position: absolute;
    top: 11px;
    right: 16px;
    width: 14px;
    height: 14px;
    appearance: none;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: $grey-70;
    color: $white;
    cursor: pointer;
    transition: 300ms all;
    z-index: 1;

    &:hover {
      background-color: $grey-120;
    }
  }

  &__remove {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
  }

  &__asterisk {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }

  &__error {
    display: block;
    margin-top: 4px;
    @extend .font-size-10;
    color: $orange-60;

    &--large {
      @extend .font-size-20;
    }
  }
}
