.static-route-list {
  flex-direction: column;
  display: flex;
  gap: 20px;

  &__static-route {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    gap: 10px;
    width: 100%;
  }

  &__add-route-btn {
    margin-top: 10px;
    border-radius: 50px;
    width: 100%;
  }

  &__delete-route {
    align-items: center;
    display: flex;
    transition: 0.3s ease;
    margin-top: 30px;
    border: 1px solid $red-60;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    background: transparent;
    cursor: pointer;
    color: $red-60;

    &:hover {
      background-color: $red-60;
      color: $white;
    }
  }
}
