.inventory-item {
  position: fixed;
  width: 320px;
  min-height: 76px;
  margin: 1px 2px;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 6px;
  box-shadow: 0px 0px 1px rgba(171, 160, 175, 0.32), 0px 1px 2px rgba(192, 184, 195, 0.32);
  transition: 300ms all;

  &:hover {
    border-color: $blue-40;
  }

  &--u-cmdb-ci-product-service:hover,
  &--internet:hover{
    border-color: $blue-100;
  }

  &--u-cmdb-ci-ethernet:hover,
  &--u-cmdb-ci-dsl:hover{
    border-color: $burple-60;
  }

  &--lan:hover{
    border-color: $teal-60;
  }

  &--u-cmdb-ci-customer-router:hover{
    border-color: $green-100;
  }

  &--cmdb-ci-ip-switch:hover{
    border-color: $mauve-60;
  }

  &--cmdb-ci-wap-network:hover{
    border-color: $pink-60;
  }

  &--wan:hover{
    border-color: $grey-100;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    z-index: 1;
  }

  &__wrap {
    font-size: 0;
  }

  &__figure {
    position: absolute;
    width: 44px;
    top: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 6px 0 0 6px;
    background-color: $blue-40;

    &--u-cmdb-ci-product-service,
    &--internet {
      background-color: $blue-100;
    }

    &--u-cmdb-ci-ethernet,
    &--u-cmdb-ci-dsl {
      background-color: $burple-60;
    }

    &--lan {
      background-color: $teal-60;
    }

    &--u-cmdb-ci-customer-router {
      background-color: $green-100;
    }

    &--cmdb-ci-ip-switch {
      background-color: $mauve-60;
    }

    &--cmdb-ci-wap-network {
      background-color: $pink-60;
    }

    &--wan {
      background-color: $grey-100;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    color: $white;
    transform: translate(-50%, -50%);

    &--u-cmdb-ci-product-service,
    &--internet {
      width: 18px;
      height: 18px;
    }

    &--u-cmdb-ci-ethernet,
    &--u-cmdb-ci-dsl {
      width: 14px;
      height: 19px;
    }

    &--lan {
      width: 21px;
    }

    &--u-cmdb-ci-customer-router {
      height: 18px;
    }

    &--cmdb-ci-ip-switch {
      width: 19px;
      height: 16px;
    }

    &--cmdb-ci-wap-network {
      height: 16px;
    }

    &--wan {
      width: 17px;
    }
  }

  &__content {
    position: relative;
    min-height: 76px;
    padding: 10px 10px 10px 54px;

    &--monitoring {
      padding-right: 53px;
    }
  }

  &__heading {
    @extend .font-size-30;
    font-weight: 500;
    color: $grey-135;
  }

  &__text {
    @extend .font-size-20;
    color: $grey-60;
  }

  &__monitoring {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}