.login {

  &__row {
    min-height: 100vh;
  }

  &__col {
    position: relative;
    flex-grow: 1;
  }

  &__col--left {
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $off-white;
    align-items: center;
  }

  &__col--right {
    overflow: hidden;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }

  &__inner {
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 20px;
    text-align: center;
  }

  &__figure {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__logo {
    width: 127px;
    height: 34px;
  }

  &__content {
    margin-top: 10px;
  }

  &__highlight {
    color: $purple-60;
  }

  &__form,
  &__error-message {
    margin: 40px auto 0;
  }

  &__form {
    display: block;
  }

  &__error-message {
    padding: 11px;
    background-color: $orange-60-overlay-8;
    border: 1px solid $orange-60-overlay-16;
    border-radius: 5px;
    text-align: center;
  }

  &__form-item+&__form-item,
  &__submit,
  &__submit+&__back-link {
    margin-top: 20px;
  }

  &__submit,
  &__back {
    width: 100%;
  }

  &__back-link {
    display: block;
  }

  &__existing-account {
    @extend .font-size-30;
    margin-top: 20px;
    line-height: 20px;
    color: $grey-135;
  }

  &__existing-account-link {
    color: $purple-60;
  }

  &__contact {
    @extend .font-size-20;
    justify-content: center;
    display: flex;
    margin-top: 15px;
    font-weight: 500;
  }

  &__link {
    @extend .font-size-20;
  }
}
