.ticket-status {
  display: inline-block;
  min-width: max-content;
  padding: 0 4px;
  @extend .font-size-5;
  font-weight: 600;
  border-radius: 2px;
  background-color: $purple-60-overlay-8;
  color: $purple-60;
  overflow: hidden;
  text-transform: uppercase;

  &--open {
    background-color: $red-60-transparent-8;
    color: $red-60;
  }

  &--inprogress {
    background-color: $green-100-transparent-8;
    color: $green-100;
  }
  
  &--resolved {
    background-color: $grey-10;
    color: $grey-100;
  }

  &--withuser,
  &--onhold {
    background-color: $blue-60-overlay-8;
    color: $blue-60;
  }

  &--closed {
    background-color: $grey-5;
    color: $grey-60;
  }
}