.time-group {
  text-align: left;

  &[data-invalid] .time-group__field {
    border-color: $orange-60;
  }

  &__field {
   position: relative;
    appearance: none;
    width: 100%;
    padding: 7px 8px;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 5px;
    color: $grey-135;
    font-weight: 400;
    @extend .font-size-20;
    transition: 300ms all;

    &[data-focus-within],
    &[data-focus-visible] {
      border-color: $purple-60;
    }

    &::placeholder {
      color: $grey-40;
      opacity: 1;
    }

    &[data-disabled] {
      background-color: $off-white;
      border-color: $grey-5;
      color: $grey-30;
    }

    &--large {
      padding: 10px 12px;
      @extend .font-size-30;
    }
  }

  &__segment {
    display: inline-block;

    &[data-focused] {
      background-color: $grey-5;
      border-radius: 2px;
    }

    &:nth-child(2) {
      margin: 0 2px;
    }
  }

  &__error {
    display: block;
    margin-top: 4px;
    @extend .font-size-10;
    color: $orange-60;

    &--large {
      @extend .font-size-20;
    }
  }
}