.tooltip {

  &__trigger,
  &__icon {
    width: 16px;
    height: 16px;
  }

  &__trigger {
    display: inline-block;
    padding: 0;
    margin-left: 4px;
    appearance: none;
    background-color: rgba(0,0,0,0);
    border: 0;
    color: $grey-40;
    transition: 300ms all;
    cursor: pointer;
    vertical-align: middle;

    &:hover {
      color: $grey-135;
    }
  }

  &__content {
    position: relative;
    padding: 6px 10px;
    border-radius: 3px;
    background-color: $grey-90;
    color: $white;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      border-style: solid;
      border-width: 3px 5px 0 5px;
      border-color: $grey-90 transparent transparent transparent;
      transform: translate(-50%, 3px);
    }
  }

  &__text {
    @extend .font-size-20;
  }
}