.asset {
  position: relative;
  margin-top: 6px;

  &__placeholder {
    align-items: center;
    flex-direction: row;
    display: flex;
    column-gap: 10px;

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-label {
      @extend .font-size-20;
    }
  }

  &__link {
    flex-direction: row;
    display: flex;
    column-gap: 10px;

    .asset__information-item-label {
      width: 32%;
    }
  }

  &-select {
    &__control {
      min-height: 32px;
      border: 1px solid $grey-10;
      background-color: $white;
      border-radius: 4px;
      box-shadow: none;
      @extend .font-size-30;
      color: $grey-135;
      cursor: pointer;

      &:hover {
        border-color: $grey-10;
      }

      &--is-focused {
        border-color: $purple-60;
      }

      &--is-disabled {
        background-color: $off-white;
        border-color: $grey-5;
        color: $grey-30;
      }
    }

    &__input-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 0 0 28px;
    }

    &__value-container {
      display: flex;
      min-height: 32px;
      padding: 0;

      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
      }
    }

    &__indicator-separator,
    &__clear-indicator {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    width: 7px;
    height: 7px;
    color: $grey-60;

    &--rotated {
      transform: rotateX(180deg);
      top: 40%;
    }
  }

  &__chosen {
    align-items: center;
    display: flex;
    column-gap: 6px;

    &-name {
      @extend .font-size-20;
      color: $grey-100;
    }
  }

  &__option {
    flex-direction: row;
    display: flex;
    column-gap: 8px;
    border-bottom: 1px solid $grey-10;
    padding: 12px 15px;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__information {
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    display: flex;

    &-name {
      @extend .font-size-20;
      color: $grey-100;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 5px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      &-link.inner-page__link {
        margin-top: 0;
      }

      &-inventory-number {
        @extend .font-size-20;
        width: 90px;
        color: $grey-100;
      }

      &--chip {
        @extend .font-size-5;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 3px;
        padding: 1px 5px;
        background-color: $grey-5;
        color: $grey-70;
      }
    }

    &-type {
      @extend .font-size-20;
      color: $grey-100;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 5px;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;

      &--chip {
        @extend .font-size-5;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 3px;
        padding: 1px 5px;
        background-color: $grey-5;
        color: $grey-70;
        width: fit-content;
      }
    }

    &-item {
      @extend .font-size-10;
      display: flex;
      column-gap: 6px;

      &-label {
        width: 40%;
        color: $grey-60;
      }

      &-value {
        color: $grey-135;
      }
    }
  }

  &__icon {
    margin-left: 1px;
    height: 12px;

    &-tick {
      align-self: center;
      width: 10px;
      height: 7px;
      color: $purple-60;
    }

    &--u-cmdb-ci-ethernet,
    &--u-cmdb-ci-dsl {
      height: 11px;
    }

    &-container {
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 100%;
      width: 24px;
      height: 24px;
      background-color: $blue-40;
      color: $white;

      &--u-cmdb-ci-ethernet,
      &--u-cmdb-ci-dsl {
        background-color: $burple-60;
      }

      &--u-cmdb-ci-customer-router {
        background-color: $green-100;
      }

      &--cmdb-ci-ip-switch {
        background-color: $mauve-60;
      }

      &--cmdb-ci-wap-network {
        background-color: $pink-60;
      }
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
