.usage-chart {
  @extend .card;
  padding: 13px 30px 25px;

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  &__heading {
    @extend .font-size-40;
    color: $grey-135;
  }

  &__chart {
    position: relative;
    margin: 28px auto 0;
    text-align: center;
  }

  &__info {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__percentage {
    @extend .font-size-70;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: $grey-135;
  }

  &__label {
    @extend .font-size-20;
    color: $grey-70;
  }
}