.assign-to {
  position: relative;

  .assign-to__control {
    margin-top: 5px;
    min-height: 32px;
    border: 1px solid $grey-10;
    background-color: $white;
    border-radius: 4px;
    box-shadow: none;
    @extend .font-size-30;
    color: $grey-135;
    cursor: pointer;
  
    &:hover {
      border-color: $grey-10;
    }

    &--is-focused {
      border-color: $purple-60;
    }

    &--is-disabled {
      background-color: $off-white;
      border-color: $grey-5;
      color: $grey-30;

      .assign-to__arrow {
        opacity: 0;
      }
    }
  }

  .assign-to__placeholder {
    margin: 0;
    color: $grey-40;
  }

  .assign-to__indicator-separator,
  .assign-to__clear-indicator {
    display: none;
  }

  .assign-to__value-container {
    min-height: 32px;
    padding: 0;

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
  }

  .assign-to__menu {
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid $grey-10;
    box-shadow: none;
  }

  .assign-to__menu-list {
    padding: 0;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    width: 7px;
    height: 7px;
    transform: translateY(-50%);
    color: $grey-60;
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 9px;
    transform: translateY(-50%);
  }

  &__empty {
    width: 16px;
    height: 16px;
  }

  &__chosen {
    position: relative;
    padding: 0 9px;
  }

  &__chosen-avatar,
  &__chosen-name {
    display: inline-block;
    vertical-align: middle;
  }

  &__chosen-avatar {
    width: 16px;
    height: 16px;
  }

  &__chosen-name {
    @extend .font-size-20;
    color: $grey-100;
  }

  &__chosen-avatar + &__chosen-name {
    margin-left: 10px;
  }

  &__loading {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__option {
    position: relative;
    padding: 8px 15px 8px 42px;
    border-radius: 4px;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }
  }

  &__avatar {
    position: absolute!important;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  &__name {
    @extend .font-size-20;
    color: $grey-100;
  }

  &__self {
    @extend .font-size-10;
    color: $grey-70;
  }
}
