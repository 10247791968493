.site {
  position: relative;
  background-color: $white;

  &__top {
    display: flex;
    min-height: 53px;
    align-items: center;
    border-bottom: 1px solid $grey-10;
  }

  &__address {
    @extend .font-size-20;
    font-weight: 400;
    color: $grey-70;
  }

  &__bottom {
    position: relative;
    width: 100%;
    height: calc(100vh - 126px);
    background-color: #F4F3F5;
  }

  &__error {
    display: flex;
    min-height: calc(100vh - 73px);
    padding: 40px 0;
    background-color: $off-white;
    align-items: center;
    justify-content: center;
  }
}
