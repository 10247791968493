.accordion {

  &__trigger {
    position: relative;
    display: block;
    padding: 0 0 0 34px;
    appearance: none;
    background-color: rgba(0,0,0,0);
    outline: none;
    border: 0;
    color: $grey-135;
    cursor: pointer;

    &[data-state="closed"] {
      .accordion__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 6px;
    width: 11px;
    height: 7px;
    transform: translateY(-50%);
    transition: 300ms all
  }

  &__title {
    @extend .font-size-60;
    font-weight: 500;
  }

  &__content {
    margin-top: 20px;

    &[data-state="open"] {
      animation: slideDown 300ms;
    }

    &[data-state="closed"] {
      opacity: 0;
      animation: slideUp 300ms;
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}