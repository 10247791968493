// Colours - general
$white: #FFFFFF;
$off-white: #FAF9FA;

// Colours - grey
$grey-5: #F4F3F5;
$grey-10: #EAE7EB;
$grey-20: #D5D0D7;
$grey-30: #C0B8C3;
$grey-40: #ABA0AF;
$grey-50: #95899B;
$grey-60: #817187;
$grey-70: #6B5A74;
$grey-80: #564260;
$grey-90: #412A4C;
$grey-95: #371D42;
$grey-100: #2C1138;
$grey-120: #220A2E;
$grey-135: #1D0B24;
$grey-30-overlay-8: rgba(192, 184, 195, 0.08);
$grey-40-overlay-16: rgba(171, 160, 175, 0.16);
$grey-100-overlay-40: rgba(44, 17, 56, 0.4);

// Colours - primary
$purple-60: #8B50E8;
$purple-80: #6A36CA;
$purple-90: #6231BB;
$purple-60-overlay-8: rgba(139, 80, 232, 0.08);
$purple-60-overlay-16: rgba(139, 80, 232, 0.16);

// Colours - status
$green-20: #9EDDD8;
$green-60: #3CBBB1;
$green-80: #36A9A0;
$lime-60: #9CCF9E;
$yellow-20: #FFF9F2;
$yellow-60: #F0CF65;
$yellow-80: #FAAE59;
$coral-60: #FF8078;
$maroon-60: #780116;
$maroon-80: #54010F;
$orange-60: #E97736;
$orange-80: #D96C2F;
$orange-90: #C96227;
$green-60-overlay-8: rgba(240, 250, 249, 1);
$green-60-overlay-16: rgba(60, 187, 177, 0.16);
$yellow-60-overlay-8: rgba(240, 207, 101, 0.08);
$yellow-60-overlay-16: rgba(240, 207, 101, 0.16);
$maroon-60-overlay-8: rgba(120, 1, 22, 0.16);
$maroon-60-overlay-16: rgba(120, 1, 22, 0.16);
$orange-60-overlay-8: rgba(233, 119, 54, 0.08);
$orange-60-overlay-16: rgba(233, 119, 54, 0.16);

// Colours - accent
$blue-40: #78B3F0;
$blue-60: #4994EC;
$blue-80: #3369FF;
$pink-60: #CB5CFA;
$green-100: #285E63;
$red-60: #D13D29;
$blue-40-overlay-16: rgba(120, 179, 240, 0.16);
$blue-60-overlay-8: rgba(73, 148, 236, 0.08);
$blue-60-overlay-16: rgba(73, 148, 236, 0.16);
$blue-80-overlay-16: rgba(51, 105, 255, 0.16);
$pink-60-overlay-16: rgba(203, 92, 250, 0.16);
$green-100-transparent-8: rgba(40, 94, 99, 0.08);
$green-80-transparent-8: rgba(54, 169, 160 ,0.08);
$orange-60-transparent-8: rgba(233, 119, 54, 0.08);
$green-100-overlay-16: rgba(40, 94, 99, 0.16);
$red-60-transparent-8: rgba(209, 61, 41, 0.08);
$red-60-overlay-16: rgba(209, 61, 41, 0.16);

// Colours - secondary 
$burple-60: #765CFF;
$mauve-60: #673D78;
$blue-100: #00128D;
$teal-60: #79C2CB;
$burple-60-overlay-8: rgba(118, 92, 255, 0.08);
$mauve-60-overlay-8: rgba(103, 61, 120, 0.08);
$blue-100-overlay-8: rgba(0, 18, 141, 0.08);
$teal-60-overlay-8: rgba(121, 194, 203, 0.08);

// Colours - Toast notifications
$error-bg: #F4EBEC;
$error-border: #E9D6DA;
$info-bg: #FAF9FA;
$info-border: #F2F0F2;
$success-bg: #EFFAF9;
$success-border: #E0F4F3;
$warning-bg: #FDF4EF;
$warning-border: #FBE9DF;

// Typography (font size, line height, optional letter spacing)
$typography: (
  0: (6px, 10px),
  5: (8px, 12px),
  10: (10px, 16px),
  20: (12px, 18px),
  25: (13px, 18px),
  30: (14px, 20px),
  40: (16px, 24px, -0.25px),
  50: (18px, 26px, -0.25px),
  60: (20px, 28px, -0.5px),
  70: (24px, 32px, -0.5px),
  80: (28px, 40px, -0.5px),
  90: (32px, 44px, -0.5px),
);

// Layout setup
$xs: 0;
$sm: 577px;
$md: 769px;
$lg: 1025px;
$xl: 1281px;
$xxl: 1681px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

$row_spacers: (
  0: 0,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 17.5px,
  5: 20px,
  6: 30px,
  7: 40px,
  8: 50px,
  9: 60px,
  10: 80px,
);

// List column key value pairs, where they key is the class number and the value is the flex width
$columns: (
  1: 8.33333%,
  2: 16.66667%,
  3: 25%,
  4: 33.33333%,
  5: 41.66667%,
  6: 50%,
  7: 58.33333%,
  8: 66.66667%,
  9: 75%,
  10: 83.33333%,
  11: 91.66667%,
  12: 100%,
);
