.fault-type {
  position: relative;

  .fault-type__control {
    margin-top: 5px;
    min-height: 32px;
    border: 1px solid $grey-10;
    background-color: $white;
    border-radius: 4px;
    box-shadow: none;
    @extend .font-size-30;
    color: $grey-135;
    cursor: pointer;
  
    &:hover {
      border-color: $grey-10;
    }

    &--is-focused {
      border-color: $purple-60;
    }

    &--is-disabled {
      background-color: $off-white;
      border-color: $grey-5;
      color: $grey-30;

      .fault-type__arrow {
        opacity: 0;
      }
    }
  }

  .fault-type__placeholder {
    margin: 0;
    color: $grey-40;
  }

  .fault-type__indicator-separator,
  .fault-type__clear-indicator {
    display: none;
  }

  .fault-type__value-container {
    min-height: 32px;
    padding: 0;

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
  }

  .fault-type__menu {
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid $grey-10;
    box-shadow: none;
  }

  .fault-type__menu-list {
    padding: 0;
  }

  .fault-type__menu-notice {
    @extend .font-size-30;
    color: $grey-60;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    width: 7px;
    height: 7px;
    transform: translateY(-50%);
    color: $grey-60;
  }

  &__loading {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__chosen,
  &__placeholder {
    position: relative;
    padding: 0 10px;
    color: $grey-100;
  }

  &__placeholder,
  &__chosen-label,
  &__option-label {
    @extend .font-size-20;
  }

  &__option-icon,
  &__option-label,
  &__chosen-icon,
  &__chosen-label,
  &__placeholder-icon,
  &__placeholder-label {
    display: inline-block;
    vertical-align: middle;
  }

  &__option-label,
  &__chosen-label,
  &__placeholder-label {
    max-width: calc(100% - 34px);
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__option-label {
    max-width: calc(100% - 14px);
  }

  &__placeholder-label {
    max-width: calc(100% - 12px);
    color: $grey-50;
  }

  &__option-icon,
  &__chosen-icon {
    width: 14px;
    height: 14px;
  }

  &__placeholder-icon {
    width: 12px;
    height: 12px;
  }

  &__option {
    position: relative;
    padding: 8px 10px;
    border-radius: 4px;
    color: $grey-100;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }
  }
}