// Basic initial row functionality
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;

  > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
}

// Load the row spacers array and loop over this for class based gutters between columns
@each $key, $gutter in $row_spacers {

  // e.g. .row-2 would have 10px padding on the left and right of a column (20px between columns in total)
  .row-#{$key} {
    display: flex;
    flex-wrap: wrap;

    @if $key == 0 {
      margin-left: #{$gutter};
      margin-right: #{$gutter}; 
    } @else {
      margin-left: -#{$gutter};
      margin-right: -#{$gutter};
    }

    > [class*="col-"] {
      padding-left: #{$gutter};
      padding-right: #{$gutter};
    }
  }
}

// Load the row spacers array and loop over this for class based gutters between columns
@each $key, $gutter in $row_spacers {

  // Loop through each responsive breakpoint
  @each $label, $space in $breakpoints {

    // Create a media query that maps the right column gutter to the right breakpoint
    @media screen and (min-width: $space) {
      
      .row-#{$label}-#{$key} {
        @if $key == 0 {
          margin-left: #{$gutter};
          margin-right: #{$gutter}; 
        } @else {
          margin-left: -#{$gutter};
          margin-right: -#{$gutter};
        }
            
        > [class*="col-"] {
          padding-left: #{$gutter};
          padding-right: #{$gutter};
        }
      }
    }
  }
}

// Loop through each column size
@each $key, $width in $columns {

  // Create a class for every key, e.g. .col-12, where the vale is $width,
  // So .col-12 has a flex width of 100%
  .col-#{$key} {
      flex: 0 0 #{$width};
      min-width: 0;
      max-width: #{$width};
  }
}

// Loop through each responsive breakpoint
@each $label, $space in $breakpoints {

  @each $key, $width in $columns {

    // Create a media query that maps the right flex width for each column to the right breakpoint
    @media screen and (min-width: $space) {
      
      // E.g. .col-md-12 will tell the column to be flex width 100% from the md breakpoint upwards
      .col-#{$label}-#{$key} {
        flex-basis: #{$width};
        max-width: #{$width};
      }
    }
  }  
}
