.card {
  position: relative;
  height: 100%;
  background-color: $white;
  border-radius: 6px;
  border: 1px solid $grey-10;
  overflow: hidden;

  &__head {
    display: flex;
    min-height: 58px;
    padding: 13px 30px;
    align-items: center;
    justify-content: space-between;

    &--small {
      padding: 13px 20px;
    }
  }

  &__heading {
    @extend .font-size-40;
    color: $grey-135;
  }

  &__loading {
    display: flex;
    min-height: 201px;
    align-items: center;
    justify-content: center;
  }

  &__body {
    padding: 17px 30px 22px;

    &--loading {
      min-height: 240px;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__link {
    display: inline-block;
    padding: 8px;
    color: $grey-70;
    cursor: pointer;
    transition: 300ms all;
    vertical-align: middle;

    &:hover {
      color: $grey-120;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}