.order-sites {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  gap: 40px;

  &__heading {
    @extend .font-size-60;
    color: $grey-135;
  }

  &__next-step-btn {
    align-self: center;
    margin-top: 60px;
  }

  &__submit {
    align-items: flex-end;
    align-self: center;
    flex-direction: column;
    display: flex;
    gap: 20px;

    .checkbox-group__content {
      width: 520px;
    }
  }

  &__list {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    gap: 20px;
    margin: 0 auto;
  }
}
