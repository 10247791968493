.datepicker-group {
  text-align: left;

  &--dashboard {

    .datepicker-group__field {
      min-width: 230px;
      padding: 7px 32px 7px 28px;
    }
  }

  &__label-wrap + &__field-wrap {
    margin-top: 8px;
  }

  &__field-wrap {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 13px;
    width: 10px;
    height: 10px;
    color: $grey-135;
    transform: translateY(-50%);
    pointer-events: none;

    &--large {
      right: 16px;
      width: 11px;
      height: 11px;
    }
  }

  &__field {
    position: relative;
    appearance: none;
    width: 100%;
    padding: 7px 8px;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 5px;
    color: $grey-135;
    font-weight: 400;
    @extend .font-size-20;
    transition: 300ms all;

    &--error {
      border-color: $orange-60;
    }

    &--large {
      padding: 10px 12px;
      @extend .font-size-30;
    }
  }

  &__content {
    z-index: 1;
  }

  &__error {
    display: block;
    margin-top: 4px;
    @extend .font-size-10;
    color: $orange-60;

    &--large {
      @extend .font-size-20;
    }
  }

  &__calendar {
    margin: 0 auto 34px!important;
    padding: 16px;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 6px;
    box-shadow: 0px 1px 12px rgba(25, 27, 35, 0.05);

    .rdp-caption {
      position: relative;
      display: block;
    }

    .rdp-caption_label {
      display: block;
      padding: 0;
      @extend .font-size-30;
      font-weight: 400;
      color: $grey-135;
      text-align: center;
      border: 0;
    }

    .rdp-nav {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      justify-content: space-between;
    }

    .rdp-nav_button {
      top: 5px;
      width: 10px;
      height: 10px;
      padding: 0;
      border: none;
      border-radius: 6px;
      color: $grey-60;
      transition: 300ms all;

      &:hover,
      &:focus,
      &:active {
        background-color: $white!important;
        color: $purple-60;
        border: none;
        cursor: pointer;
      }
    }

    .rdp-nav_button_previous {
      left: 16px;
    }

    .rdp-nav_button_next {
      left: auto;
      right: 16px;
    }

    .rdp-table {
      margin-top: 12px;
    }

    .rdp-head_cell {
      width: 32px;
      height: 32px;
      @extend .font-size-30;
      font-weight: 400;
      color: $grey-60;
      text-transform: capitalize;
    }

    .rdp-cell,
    .rdp-day {
      width: 32px;
      height: 32px;
    }

    .rdp-day {
      background-color: $white;
      border-radius: 6px;
      border: 0;
      outline: none;
      @extend .font-size-30;
      font-weight: 400;
      color: $grey-135;
      transition: 300ms;

      &.rdp-day_today,
      &.rdp-day_today:focus {
        background-color: $white;
        font-weight: 400;
      }

       &.rdp-day_today:after {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 6px;
        border: 1px solid $grey-20;
       }

      &:hover,
      &:focus {
        background-color: $purple-80!important;
        border: 0;
        color: $white;
        outline: none;
      }
    }

    .rdp-day_outside {
      background-color: $grey-10;
    }

    .rdp-day_selected,
    .rdp-day_selected:focus,
    .rdp-day_selected.rdp-day_today,
    .rdp-day_selected.rdp-day_today:focus {
      background-color: $purple-60;
      color: $white;
    }
  }

  &__asterisk {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }
}