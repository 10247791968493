.order-connection {
  flex: 1;
  grid-template-areas:
    "heading"
    "bearer-speed"
    "items";
  display: grid;
  gap: 20px;
  border: 1px solid $purple-60;
  border-radius: 16px;
  padding: 15px 20px;
  max-width: 50%;

  &__connection-heading {
    flex-direction: column;
    grid-area: heading;
    display: flex;
    gap: 2px;
  }

  &__connection-type {
    @extend .font-size-30;
  }

  &__technology {
    @extend .font-size-20;
    font-weight: 400;
    color: $grey-80;
  }

  &__bearer-speed {
    grid-area: bearer-speed;
    display: flex;
    gap: 30px;
  }

  &__label {
    @extend .font-size-10;
    line-height: 1.4;
    color: $grey-80;
  }

  &__mbps {
    @extend .font-size-10;
  }

  &__items {
    align-items: flex-end;
    grid-area: items;
    justify-content: space-between;
    display: flex;
    gap: 20px;
  }

  &__item {
    align-items: center;
    flex: 1;
    flex-direction: column;
    display: flex;
    gap: 8px;
    text-align: center;

    &-isRequired {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 20px;
      height: 20px;

      &--true {
        color: $green-80;
      }

      &--false {
        width: 15px;
        height: 15px;
        color: $red-60;
      }
    }
  }
}
