.orders {
  align-items: flex-start;
  gap: 20px;

  &__heading {
    @extend .font-size-60;
    color: $grey-135;
    margin-bottom: 20px;
  }

  &__table-modifiers {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__create-request-btn{
    text-wrap: nowrap;
  }
}
