.gauge {
  margin: 0 auto;

  .dial {
    stroke-width: 16px;
    stroke: $grey-20;
  }

  .value {
    position: relative;
    stroke-width: 16px;
  }
}