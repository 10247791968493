.order-site-vrf {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  gap: 40px;

  &__order-details-container {
    margin: 0 auto;
    width: $xl;
    padding-bottom: 80px;
  }

  &__section {
    position: relative;
    flex-direction: row;
    justify-content: center;
    display: flex;
  }

  &__order-heading {
    @extend .font-size-70;
    text-align: center;
  }

  &__order-instructions {
    @extend .font-size-25;
    margin: 0 auto;
    width: 70%;
    text-align: center;
  }

  &__form-row {
    margin-top: 20px;
    margin-bottom: 20px;

    &--extra-margin {
      margin-top: 40px;
    }
  }

  &__form-field-group-description {
    position: absolute;
    top: 20px;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--top {
      top: 600px;
    }
  }

  &__order-details {
    flex-direction: column;
    display: flex;
    gap: 10px;
    border: 1px solid $grey-10;
    width: 700px;
    padding: 20px 40px;
    background-color: $white;

    &--top {
      border-bottom: 0;
      border-radius: 30px 30px 0 0;
      padding-top: 50px;
    }

    &--middle {
      border-top: 0;
      border-bottom: 0;
      border-radius: 0;
    }

    &--bottom {
      box-shadow: 0 4px 2px -2px rgb(235, 233, 233);
      border-top: 0;
      border-radius: 0 0 30px 30px;
      padding-bottom: 50px;
    }
  }

  &__section-heading {
    @extend .font-size-40;
    color: $grey-135;
  }

  &__section-subheading {
    @extend .font-size-20;
  }

  &__details {
    border: 1px solid $purple-60;
    border-radius: 16px;
    padding: 15px 20px;
  }

  &__available-speed {
    @extend .font-size-20;
    align-items: flex-end;
    display: flex;
    height: 50px;
    color: $grey-135;
  }

  &__icon {
    width: 18px;
    height: 18px;
    color: $green-100;
  }

  &__failover-vrf button {
    cursor: pointer;
  }

  &__routing {
    margin-top: 20px;
  }

  &__add-subnet-btn {
    border-radius: 50px;
  }

  &__actions {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    margin-top: 30px;
  }

  &__save-btn {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  &__disabled-toggle-label {
    @extend .font-size-20;
    color: $grey-135;
    align-items: flex-end;
    display: flex;
    height: 30px;
  }
}
