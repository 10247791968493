.create-request {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;

  &__content {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    min-width: 210px;
    padding: 10px;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 6px;
    box-shadow: 0px 1px 12px rgba(25, 27, 35, 0.05);
  }

  &__list {
    display: block;
    padding-left: 0;
    list-style-type: none;
  }

  &__item {
    display: block;
    list-style-type: none;
  }

  &__action {
    display: block;
    width: 100%;
    padding: 6px 8px;
    appearance: none;
    background-color: rgba(0,0,0,0);
    border-radius: 6px;
    border: 0;
    @extend .font-size-25;
    text-align: left;
    color: $grey-100;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      background-color: $grey-5;
    }
  }
}