.data-table {
  padding: 20px 0 30px;

  &--no-padding {
    padding-top: 0;
  }

  &__upper {
    padding-top: 20px;
    background-color: $white;
    border-bottom: 1px solid $grey-10;
  }

  &__container {
    min-height: 32px;
  }

  &__row {
    align-items: center;
  }

  &__col--right {
    justify-content: flex-end;
    display: flex;
    text-align: right;
  }

  &__col--tabs {
    margin-top: 16px;
  }

  &__outer {
    margin-top: 20px;
    padding: 0 30px;
  }

  &__inner {
    border-radius: 6px;
    border: 1px solid $grey-10;
    background-color: $white;
    overflow: hidden;
    position: relative; // Required to make table footer always visible
  }

  &__action {
    margin-right: 12px;
  }

  &__export+&__search {
    margin-left: 12px;
  }

  &__export {
    &-link {
      align-items: center;
      display: flex;
      margin-right: 4px;
    }

    &--loading {
      border-color: $grey-20;
      color: $grey-30;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__search {
    width: calc(100% - 103px);
    vertical-align: middle;
  }

  &__loading,
  &__no-results {
    min-height: calc(100vh - 175px);
  }

  &__raise-button {
    margin-right: 12px;
  }

  &__list {
    display: block;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    overflow: auto;
    list-style: none;
    white-space: nowrap;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: inline-block;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    padding: 9px 0;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    font-size: 0;
    color: $grey-95;
    transition: 300ms all;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      right: 50%;
      bottom: 0;
      left: 50%;
      width: 0;
      border-bottom: 2px solid $grey-95;
      transition: 300ms all;
    }

    &:hover,
    &--active {

      &:after {
        right: 0;
        left: 0;
        width: 100%;
      }
    }

    &:disabled,
    &:disabled:hover {
      color: $grey-30;

      &:after {
        border-bottom-color: $grey-30;
      }
    }
  }

  &__icon-wrap,
  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &__no-truncate {
    max-width: 30vw;
    white-space: initial;
  }

  &__icon-wrap {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: $grey-30;
    border-radius: 3px;
    color: $white;

    &--all {
      background-color: $grey-60;
    }

    &--circuits {
      background-color: $burple-60;
    }

    &--devices {
      background-color: $coral-60;
    }

    &--licenses {
      background-color: $lime-60;
    }
  }

  &__icon-wrap+&__label {
    margin-left: 8px;
  }

  &__label {
    @extend .font-size-30;
    font-weight: 500;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 9px;
    transform: translate(-50%, -50%);

    &--circuits {
      width: 15px;
      height: 15px;
    }

    &--devices {
      width: 14px;
      height: 12px;
    }

    &--licenses {
      width: 11px;
      height: 11px;
    }
  }
}
