.inventory {
  height: auto;
  min-height: calc(100vh - 196px);

  &__wrap {
    align-items: center;
    flex-direction: row;
    gap: 25px;
    justify-content: space-between;
    display: flex;
    border: 1px solid $grey-10;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    background-color: $white;
    padding: 15px 30px 10px;
  }

  &__item-details {
    flex: auto;
    flex-direction: column;
    gap: 4px;
    display: flex;
  }

  &__name-status {
    align-items: center;
    gap: 10px;
    display: flex;
  }

  &__type {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $blue-80;
    color: $white;

    &--u-cmdb-ci-ethernet,
    &--u-cmdb-ci-dsl {
      background-color: $burple-60;
    }

    &--cmdb-ci-ip-firewall {
      background-color: $blue-40;
    }

    &--u-cmdb-ci-product-service,
    &--internet {
      background-color: $blue-100;
    }

    &--u-cmdb-ci-customer-router {
      background-color: $green-100;
    }

    &--cmdb-ci-ip-switch {
      background-color: $mauve-60;
    }

    &--lan {
      background-color: $teal-60;
    }

    &--wan {
      background-color: $grey-100;
    }

    &--cmdb-ci-wap-network {
      background-color: $pink-60;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);

    &--u-cmdb-ci-ethernet,
    &--u-cmdb-ci-dsl {
      width: 24px;
      height: 32px;
    }

    &--cmdb-ci-ip-firewall {
      width: 26px;
    }

    &--u-cmdb-ci-customer-router {
      width: 32px;
      height: 31px;
    }

    &--cmdb-ci-ip-switch {
      width: 32px;
      height: 27px;
    }

    &--lan {
      width: 35px;
      height: 33px;
    }

    &--wan {
      width: 29px;
      height: 33px;
    }

    &--cmdb-ci-wap-network {
      width: 33px;
      height: 26px;
    }
  }

  &__heading {
    font-weight: 500;
    color: $grey-135;
  }

  &__link {
    align-items: center;
    gap: 10px;
    display: flex;
    font-size: 0;
    color: $purple-60;
    text-decoration: none;
    transition: 300ms all;

    &:hover {
      color: $purple-90;
    }
  }

  &__link-label {
    @extend .font-size-30;
    font-weight: 500;
  }

  &__link-icon {
    width: 11px;
    height: 11px;
  }

  &__row--empty {
    min-height: 100%;
  }

  &__col {
    margin-top: 20px;

    &--no-margin {
      margin-top: 0;
    }
  }

  &__table-heading {
    @extend .font-size-40;
    color: $grey-135;
    font-weight: 500;
  }

  &__table-wrap {
    min-height: 0;
  }

  &__tabs {
    border: 1px solid $grey-10;
    border-top: none;
    border-radius: 0 0 8px 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &__list {
    border-bottom: 1px solid $grey-10;
    background-color: $white;
  }

  &__main {
    padding: 15px 30px 0;
  }
}
