.sites-map {
  position: relative;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey-10;

  a[href^="https://maps.google.com/maps"] {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  &__map {
    width: 100%;
    height: 300px;
  }

  &__help {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &__button {
    position: relative;
    width: 44px;
    height: 44px;
    border: 0;
    border-radius: 2px;
    appearance: none;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    color: #666666;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      color: $grey-135;
    }
  }

  &__question {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 16px;
    transform: translate(-50%, -50%);
  }

  &__card {
    width: 250px;
    padding: 15px 20px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(18, 28, 45, 0.1);
    z-index: 2;

    &--help {
      position: absolute;
      right: 0;
      bottom: calc(100% + 55px);
      width: 340px;

      &:after {
        content: '';
        position: absolute;
        right: 18px;
        bottom: -4.5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: $white transparent transparent transparent;
      }
    }

    &--info {
      margin: 10px;
    }
  }

  &__heading {
    @extend .font-size-30;
    font-weight: 500;
    color: $purple-60;
  }

  &__content {
    margin: 5px 0 0;
    @extend .font-size-20;
    color: $grey-70;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    padding: 0;
    border: 0;
    border-radius: 50%;
    appearance: none;
    background-color: rgba(0,0,0,0);
    background-color: $grey-70;
    color: $white;
    cursor: pointer;
    transform: translate(7px, -7px);
    transition: 300ms all;

    &:hover {
      background-color: $grey-120;
    }

    &--info {
      transform: translate(-3px, 3px);
    }
  }

  &__close-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
  }

  &__link {
    position: relative;
    display: inline-block;
    color: $purple-60;
    transition: 300ms all;

    &:hover {
      color: $purple-90;
    }
  }

  &__icon { 
    width: 11px;
    height: 11px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%);
  }

  &__link-label {
    display: inline-block;
    padding-right: 19px;
    @extend .font-size-30;
    font-weight: 500;
  }

  &__expand {
    position: absolute;
    top: 50%;
    right: 0;
    width: 11px;
    height: 11px;
    transform: translateY(-50%);
  }
}