.inner-page {
  padding: 20px 30px 64px;

  &__back {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 0;
    appearance: none;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    color: $grey-100;
    transition: 300ms all;

    &:hover {
      border-color: $purple-60-overlay-16;
    }
  }

  &__back-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 14px;
    transform: translate(-50%, -50%);
  }

  &__main {
    display: flex;
    height: calc(100vh - 196px);
    padding-top: 15px;
  }

  &__col {
    position: relative;
    overflow: auto;
  }

  &__col--left {
    flex: 0 1 330px;
    min-width: 330px;
  }

  &__col--right,
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &__col--right {
    margin-left: 20px;
  }

  &__card {
    position: relative;
    overflow: auto;
    border: 1px solid $grey-10;
    background-color: $white;
    padding: 20px;
    border-radius: 8px;

    +.inner-page__card {
      margin-top: 15px;
    }
  }

  &__subheading {
    margin: 28px 0 0;
    @extend .font-size-10;
    font-weight: 600;
    text-transform: uppercase;
    color: $grey-70;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__content,
  &__link,
  &__wrap,
  &__read {
    margin-top: 9px;
  }

  &__content,
  &__link,
  &__name,
  &__text {
    font-weight: 400;
    color: $grey-100;

    &--small {
      color: $grey-70;
    }
  }

  &__name {
    max-width: 15vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__content+&__content--small {
    margin-top: 0;
  }

  &__link,
  &__name {
    @extend .font-size-25;
  }

  &__link {
    display: inline-block;
    color: $purple-60;
    text-decoration: none;
    transition: 300ms all;

    &:hover {
      color: $purple-90;
    }
  }

  &__ticket-data {
    padding: 27px 20px 28px;
    height: 100%;
  }

  &__upper {
    padding: 22px 30px 0;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 8px 8px 0 0;
  }

  &__upper-wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 76px;
  }

  &__left {
    max-width: calc(100% - 280px);
    flex-basis: calc(100% - 280px);
  }

  &__right {
    max-width: 280px;
    flex-basis: 280px;
    padding-left: 20px;
    text-align: right;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    max-width: 56px;
    height: 57px;
    transform: translateY(-50%);
  }

  &__heading {
    @extend .font-size-40;
    font-weight: 500;
    color: $grey-95;
  }

  &__info {
    display: inline-block;
    margin: 5px 0 0;
    @extend .font-size-30;
    font-weight: 400;
    color: $grey-60;
  }

  &__info+&__info {
    margin-left: 20px;
  }

  &__email {
    color: $purple-60;
  }

  &__opened {
    color: $grey-135;
  }

  &__trigger,
  &__trigger-dot,
  &__trigger-label {
    display: inline-block;
    vertical-align: middle;
  }

  &__trigger-dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: $red-60;
    overflow: hidden;

    &--black {
      background-color: $grey-100;
    }

    &--blue {
      background-color: $blue-80;
    }

    &--grey {
      background-color: $grey-60;
    }

    &--purple {
      background-color: $purple-60;
    }
  }

  &__trigger-dot+&__trigger-label {
    margin-left: 10px;
  }

  &__top-button {
    margin-left: 10px;
    vertical-align: middle;
  }

  &__comments-wrapper {
    padding: 0;
    background-color: transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  &__comments {
    flex: 1;
    overflow: auto;
    padding: 20px 30px 40px;
  }

  &__avatar,
  &__name {
    display: inline-block;
    vertical-align: middle;
  }

  &__avatar+&__name {
    margin-left: 8px;
  }

  &__loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bookend {
    @extend .font-size-20;
    color: $grey-70;
    text-align: center;

    &--bottom {
      margin-top: 28px;
    }
  }

  &__list {
    margin-top: 14px;
    padding: 0;
  }

  &__tab {
    height: calc(100% - 125px);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    border: 1px solid $grey-10;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    &[data-state="inactive"] {
      display: none;
    }
  }

  &__edit {
    margin-top: 20px;
  }

  &__form {
    position: relative;
    margin-top: 2px;
  }

  &__input {
    padding: 7px 32px 7px 8px;
    @extend .font-size-25;

    &:focus {
      border-color: $purple-60;
    }

    &[readonly] {
      padding-left: 0;
      padding-right: 0;
      border-color: $white;
      background-color: rgba(0, 0, 0, 0);
      color: $grey-100;
    }

    &:disabled {
      background-color: $off-white;
      border-color: $grey-10;
    }
  }

  &__edit-input,
  &__save {
    position: absolute;
    top: 50%;
    right: 8px;
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    appearance: none;
    background-color: rgba(0, 0, 0, 0);
    color: $grey-60;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      color: $purple-60;
    }
  }

  &__save {
    color: $purple-60;

    &:hover {
      color: $purple-90;
    }
  }

  &__edit-icon,
  &__save-icon,
  &__save-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__edit-icon,
  &__save-icon {
    width: 10px;
    height: 10px;
  }

  &__save-icon {
    height: 7px;
  }

  &__read {
    position: relative;
  }

  &__wrapper {
    position: relative;
    max-height: 123px;
    transition: height 300ms ease-in-out;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 18px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%);
      opacity: 1;
    }

    &--expanded {
      max-height: initial;
      transition: height 300ms ease-in-out;

      &:after {
        opacity: 0;
      }
    }
  }

  &__toggle-wrap {
    padding-top: 4px;

    &--expanded {
      padding-top: 14px;
    }
  }

  &__toggle {
    display: inline-block;
    appearance: none;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    @extend .font-size-25;
    font-weight: 500;
    color: $grey-95;
    text-decoration: underline;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      color: $grey-70;
    }
  }

  &__new-wrap {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    text-align: center;
    transition: 300ms all;
    z-index: 2;

    &--active {
      opacity: 1;
      pointer-events: initial;

    }
  }

  &__new {
    display: inline-block;
    padding: 5px 8px;
    appearance: none;
    border: 0;
    border-radius: 2px;
    background-color: $purple-60;
    color: $white;
    font-size: 0;
    box-shadow: 0px 1px 2px 0px rgba(44, 17, 56, 0.05);
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      background-color: $purple-90;
    }
  }

  &__new-label,
  &__new-icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__new-label+&__new-icon {
    margin-left: 10px;
  }

  &__new-label {
    @extend .font-size-30;
    font-weight: 500;
  }

  &__new-icon {
    max-width: 8px;
    height: 10px;
  }

  &__empty {
    display: flex;
    min-height: calc(100vh - 196px);
    padding: 40px 0;
    align-items: center;
    justify-content: center;
  }
}
