.navigation {
  display: flex;
  height: 73px; 
  background-color: $white;
  border-bottom: 1px solid $grey-10;
  align-items: center;
  grid-area: navbar;

  &__row {
    align-items: center;
  }

  &__col--right {
    position: relative;
    text-align: right;
    z-index: 4;
  }

  &__heading {
    color: $grey-100;
    font-weight: 500;
  }

  &__menu {
    position: relative;
    display: inline-flex;
    appearance: none;
    border: 0;
    padding: 0;
    background-color: rgba(0,0,0,0);
    align-items: center;
    cursor: pointer;
    transition: 300ms all;

    &:hover {

      .navigation__arrow-wrap {
        border-color: $purple-60-overlay-16;
      }
    }
  }

  &__avatar {
    margin-right: 10px;
  }

  &__label {
    @extend .font-size-30;
    font-weight: 500;
    color: $grey-95;
  }

  &__arrow-wrap {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 4px;
    border: 2px solid rgba(0,0,0,0);
    vertical-align: middle;
    transition: 300ms all;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 5px;
    color: $grey-60;
    transform: translate(-50%, -50%);
    transition: 300ms all;

    &--active {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &__dropdown {
    margin-top: 20px;
    min-width: 300px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $grey-5;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    text-align: left;
    overflow: hidden;
  }

  &__top {
    position: relative;
    padding: 12px 16px 12px 68px;
    border-bottom: 1px solid $grey-5;
    z-index: 8;
  }

  &__menu-avatar {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  &__user {
    font-weight: 500;
    color: $grey-135;
  }

  &__email {
    color: $grey-60;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
  }

  &__item {
    display: block;
    border-top: 1px solid $grey-5;

    &:first-child {
      border-top: 0;
    }
  }

  &__link {
    display: block;
    width: 100%;
    appearance: none;
    border: 0;
    padding: 10px 16px;
    @extend .font-size-30;
    background-color: rgba(0,0,0,0);
    color: $grey-90;
    text-align: left;
    text-decoration: none;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      background-color: $purple-60;
      color: $white;
    }
  }
}