.report-problem {

  &__heading {
    @extend .font-size-60;
  }

  &__subheading {
    margin-top: 10px;
    @extend .font-size-30;
    color: $grey-60;
  }

  &__actions {
    margin-top: 50px;
    text-align: right;
  }

  &__button + &__button {
    margin-left: 12px;
  }

  &__button--report {
    min-width: 64px;
  }
}