.admin-dashboard {
  padding: 33px 0 40px;

  &__row {
    align-items: center;
  }

  &__col--right {
    text-align: right;
  }

  &__heading,
  &__time {
    @extend .font-size-90;
    font-weight: 500;
    color: $grey-135;
  }

  &__date {
    @extend .font-size-30;
    font-weight: 400;
    color: $grey-80;
  }

  &__date + &__time {
    margin-top: 6px;
  }

  &__section {
    margin-top: 40px;
  }

  &__section-col--dates {
    margin-bottom: 20px;   
  }

  &__section-col--table {
    margin-top: 20px;
  }

  &__card {
    @extend .card;
    padding: 13px 30px 25px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    height: 100%;
  }

  &__heading {
    @extend .font-size-40;
    color: $grey-135;
  }

  &__wrap {
    display: inline-block;
    text-align: center;
  }

  &__days {
    @extend .font-size-90;
    color: $grey-135;
    font-weight: 500;
    text-transform: capitalize;
  }

  &__since {
    margin-top: 6px;
  }

  &__arrow,
  &__label {
    display: inline-block;
    color: $green-60;
    vertical-align: middle;
  }

  &__label {
    @extend .font-size-30;
    margin-left: 6px;
  }

  &__arrow {
    max-width: 8px;
    height: 7px;
  }

  &__picker-wrap,
  &__hyphen {
    display: inline-block;
    vertical-align: middle;
  }

  &__picker-wrap {
    position: relative;
    color: $grey-135;
  }

  &__calendar,
  &__picker-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    pointer-events: none;
  }

  &__calendar {
    left: 8px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%);
  }

  &__picker-arrow {
    right: 13px;
    width: 7px;
    height: 4px;
    transform: translateY(-50%);
  }

  &__hyphen {
    margin: 0 6px;
    color: $grey-60;
  }

  &__avatar,
  &__org {
    display: inline-block;
    vertical-align: middle;
  }

  &__avatar + &__org {
    margin-left: 8px;
  }

  &__table-link {
    color: $grey-100;
    text-decoration: none;
    transition: 300ms all;

    &:hover {
      color: $purple-60;
    }
  }

  &__dialog-table {
    border: 1px solid $grey-10;
    border-radius: 6px;
  }

  &__dialog-headers {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dialog-close {
    appearance: none;
    padding: 0px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
      width: 12px;

      path {
        fill: $grey-70;
      }
    }
  }

  &__dialog-heading {
    @extend .font-size-70;
    font-weight: 500;
    color: $grey-135;
  }
}