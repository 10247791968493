.input-group {
  text-align: left;

  &__label-wrap {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }

  &__label-wrap + &__field-wrap {
    margin-top: 8px;
  }

  &__field-wrap {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 9px;
    transform: translateY(-50%);
  }

  &__field {
    position: relative;
    appearance: none;
    width: 100%;
    padding: 7px 8px;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 5px;
    color: $grey-135;
    font-weight: 400;
    @extend .font-size-20;
    transition: 300ms all;

    &:focus {
      border-color: $purple-60;
    }

    &::placeholder {
      color: $grey-40;
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      background-color: $off-white;
      border-color: $grey-5;
      color: $grey-30;
    }

    &--error {
      border-color: $orange-60;
    }

    &--large {
      padding: 10px 12px;
      @extend .font-size-30;

      &.input-group__field--leading {
        padding-left: 36px;
      }
    }

    &--leading {
      padding-left: 32px;
    }
  }

  &__error {
    display: block;
    margin-top: 4px;
    @extend .font-size-10;
    color: $orange-60;

    &--large {
      @extend .font-size-20;
    }
  }

  &__button-wrap {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    border-radius: 5px;
    appearance: none;
    background-color: rgba(0,0,0,0);
    color: $grey-60;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      background-color: $off-white;

      + .input-group__text-label {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &__text-label {
    position: absolute;
    top: 0;
    right: 50%;
    width: max-content;
    padding: 7px 10px;
    background-color: $grey-120;
    border-radius: 4px;
    @extend .font-size-10;
    color: $white;
    opacity: 0;
    pointer-events: none;
    transform: translate(50%, -30px);
    transition: 300ms all;

    &:after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 50%;
      border-style: solid;
      border-width: 7px 5.5px 0 5.5px;
      border-color: $grey-120 transparent transparent transparent;
      transform: translateX(-50%);
    }
  }

  &__asterisk {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }
}