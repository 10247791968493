.mfa-settings {
  margin: 50px 30px;
  background-color: $white;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  max-width: 700px;
  display: flex;

  &__content {
    flex: 1;
  }

  &__title {
    @extend .font-size-50;
    color: $grey-135;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__desc {
    @extend .font-size-30;
    color: $grey-60;
  }

  &__disable-title {
    @extend .font-size-50;
    color: $grey-135;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__disable-desc {
    @extend .font-size-30;
    color: $grey-60;
    margin-bottom: 30px;
  }

  &__disable-digits {
    margin-bottom: 40px;
  }

  &__disable-buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel {
    margin-right: 12px;
  }
}
