.search-form {
  position: relative;
  display: inline-block;
  max-width: 370px;
  width: 100%;

  &__input {
    position: relative;
    width: 100%;
    padding-left: 32px;
    line-height: 19px;
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 16px;
    height: 16px;
    appearance: none;
    border: 0;
    border-radius: 3px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    color: $grey-100;
    transform: translateY(-50%);
    transition: 300ms all;
    cursor: pointer;

    &--clear {
      left: unset;
      right: 8px;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-clip: $off-white;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
    transform: translate(-50%, -50%);

    &--clear {
      color: $grey-100;
      width: 10px;
      height: 10px;
    }
  }
}
