.upgrade-modal {
  top: auto;
  right: 30px;
  bottom: 30px;
  left: auto;
  max-width: 220px;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15);
  transform: none;

  &__wrap {
    padding: 15px 20px;
  }

  &__heading {
    @extend .font-size-40;
    font-weight: 500;
    color: $grey-135;
  }

  &__text {
    margin-top: 7px;
    @extend .font-size-20;
    color: $grey-80;
  }
}