.percentage-bar {
  min-width: 90px;

  &__outer,
  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &__outer,
  &__inner {
    height: 8px;
  }

  &__outer {
    max-width: 50px;
    width: 100%;
    background-color: $grey-10;
    border-radius: 2px;
    overflow: hidden;
  }

  &__inner {
    background-color: $green-60;

    &--yellow {
      background-color: $yellow-60;
    }

    &--orange {
      background-color: $orange-60;
    }

    &--red {
      background-color: $maroon-60;
    }
  }

  &__label {
    margin-left: 8px;
    @extend .font-size-25;
    color: $grey-100;
  }
}