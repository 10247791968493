@mixin font-attributes($attributes) {
  @if type-of($attributes) == "list" {
    font-size: nth($attributes, 1);

    @if (length($attributes) == 2) {
      line-height: nth($attributes, 2);
    }

    @if (length($attributes) == 3) {
      line-height: nth($attributes, 2);
      letter-spacing: nth($attributes, 3);
    }
  }
}