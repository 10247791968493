.order-status {
  @extend .font-size-5;
  display: inline-block;
  min-width: max-content;
  padding: 0 4px;
  border-radius: 2px;
  background-color: $purple-60-overlay-8;
  font-weight: 600;
  text-transform: uppercase;
  color: $purple-60;
  overflow: hidden;

  &--inprogress {
    background-color: $orange-60-transparent-8;
    color: $orange-60;
  }
  
  &--submitted {
    background-color: $purple-60-overlay-8;
    color: $purple-60;
  }

  &--completed {
    background-color: $green-80-transparent-8;
    color: $green-80;
  }
}
