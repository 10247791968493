.change-password {
  margin: 50px 30px;
  background-color: $white;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  max-width: 640px;

  &__title {
    @extend .font-size-50;
    font-weight: 500;
    color: $grey-135;
    margin-bottom: 8px;
  }

  &__desc {
    @extend .font-size-30;
    color: $grey-60;
    margin-bottom: 35px;
  }

  &__row {
    margin-bottom: 25px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
}
