.digits {
  position: relative;
  padding: 0;
  appearance: none;
  border: 0;
  text-align: left;

  &__label {
    display: block;
    margin-bottom: 6px;
    @extend .label;
    color: $grey-80;
  }

  &__input {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-left: 8px;
    background-color: $white;
    border: 1px solid $grey-10;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: 60px;
    color: $purple-60;
    text-align: center;

    &::placeholder {
      color: $grey-10;
      opacity: 1;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__hidden {
    position: absolute;
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &__message {
    margin: 6px 0 0;
    @extend .font-size-20;
    text-align: left;
    color: $grey-70;
  }

  &__link {
    @extend .font-size-20;
    color: $grey-70;

    &:after {
      background-color: $grey-70;
    }
  }
}