.customers-table {
  &__name-row {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: 8px;
  }

  &__remove-title {
    @extend .font-size-50;
    font-weight: 500;
    color: $grey-135;
    margin-bottom: 8px;
  }

  &__remove-desc {
    @extend .font-size-30;
    color: $grey-60;
    margin-bottom: 30px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  &__link {
    color: inherit;
    text-decoration: none;
  }
}
