.order-site {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  gap: 40px;

  &__order-details-container {
    margin: 0 auto;
    width: $xl;
    padding-bottom: 80px;
  }

  &__section {
    position: relative;
    flex-direction: row;
    justify-content: center;
    display: flex;
  }

  &__order-heading {
    @extend .font-size-70;
    text-align: center;
  }

  &__order-instructions {
    @extend .font-size-25;
    margin: 0 auto;
    width: 70%;
    text-align: center;
  }

  &__form-field-group-description {
    position: absolute;
    top: 20px;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__order-details {
    flex-direction: column;
    display: flex;
    gap: 10px;
    border: 1px solid $grey-10;
    width: 700px;
    padding: 20px 40px;
    background-color: $white;

    &--top {
      border-bottom: 0;
      border-radius: 30px 30px 0 0;
      padding-top: 50px;
    }

    &--middle {
      border-top: 0;
      border-bottom: 0;
      border-radius: 0;
    }

    &--bottom {
      box-shadow: 0 4px 2px -2px rgb(235, 233, 233);
      border-top: 0;
      border-radius: 0 0 30px 30px;
      padding-bottom: 50px;
    }
  }

  &__section-heading {
    @extend .font-size-40;
    color: $grey-135;
  }

  &__section-subheading {
    @extend .font-size-20;
  }

  &__items {
    justify-content: space-between;
    display: flex;
    gap: 30px;
  }

  &__sip {
    flex-direction: column;
    display: flex;
    gap: 15px;

    .select-group {
      width: 50%;
    }
  }

  &__lan-interface-operation-mode .select-group {
    width: 50%;
  }

  &__radio-group {
    flex-direction: column;
    display: flex;
    gap: 8px;
  }

  &__radio-label {
    align-items: center;
    display: flex;
    gap: 8px;
    width: fit-content;
    cursor: pointer;
  }

  &__radio-input {
    width: 20px;
    height: 20px;
    cursor: pointer;
    accent-color: $purple-60;
  }

  &__vrfs {
    flex-direction: column;
    display: flex;
    gap: 20px;
  }

  &__vrf-list {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    gap: 20px;

    .vrf-option {
      width: 48%;
    }
  }

  &__checklist {
    padding: 0;

    &-item {
      align-items: center;
      display: flex;
      gap: 10px;
      list-style-type: none;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;

    &--tick {
      color: $green-100;
    }

    &--cross {
      color: $red-60;
    }
  }

  &__actions {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    margin-top: 30px;
  }

  &__save-btn {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  &__disabled-toggle-label {
    @extend .font-size-40;
    display: flex;
    height: 48px;
  }

  &__submit {
    flex-direction: column;
    align-items: center;
    align-self: center;
    display: flex;
    gap: 20px;
    margin-top: 30px;
    width: 100%;

    .checkbox-group__content {
      width: 520px;
    }
  }
}
