.toast {
  display: inline-block;
  padding: 11px 30px;
  background-color: $success-bg;
  border: 1px solid $success-border;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
  z-index: 6;

  &--info {
    background-color: $info-bg;
    border-color: $info-border;
  }

  &--error {
    background-color: $error-bg;
    border-color: $error-border;
  }

  &--warning {
    background-color: $warning-bg;
    border-color: $warning-border;
  }

  // &--visible {
  //   animation: animateIn 0.2s ease-in-out;
  //   animation-fill-mode: forwards;
  // }

  // &--hidden {
  //   animation: animateIn 0.2s ease-in-out;
  //   animation-fill-mode: forwards;
  // }
}
