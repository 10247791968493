.user-dropdown {
  @extend .font-size-30;
  min-width: 250px;

  &__error-message {
    color: $red-60;
    text-align: center;
  }

  &__placeholder {
    align-items: center;
    flex-direction: row;
    display: flex;
    padding-left: 8px;

    &-label {
      @extend .font-size-30;
    }
  }

  &-select {
    &__control {
      box-shadow: none;
      border: 1px solid $grey-10;

      &:hover {
        border-color: $grey-10;
      }

      &--is-focused {
        border-color: $purple-60 !important;
      }
    }

    &__input-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0 0 0 6px !important;
      margin: 0 !important;
    }

    &__value-container {
      display: flex;
      min-height: 32px;
      padding: 0;

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
      }
    }

    &__indicator-separator,
    &__clear-indicator {
      display: none;
    }
  }

  &__chosen {
    padding-left: 8px;

    &-name {
      @extend .font-size-30;
      color: $grey-100;
    }
  }

  &__option {
    display: flex;
    border-bottom: 1px solid $grey-10;
    padding: 12px 15px;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__information {
    align-items: center;
    flex-direction: row;
    display: flex;
    gap: 12px;

    &--name {
      @extend .font-size-30;
      color: $grey-100;
    }

    &--organisation {
      @extend .font-size-10;
      border-radius: 3px;
      padding: 1px 12px;
      background-color: $grey-5;
      color: $grey-70;
    }
  }
}
