.empty-state {
  display: inline-block;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  &__figure {
    display: inline-block;
  }

  &__icon {
    width: 56px;
    height: 56px;
  }

  &__heading {
    @extend .font-size-40;
    color: $grey-95;
    font-weight: 500;
  }

  &__content {
    margin-top: 4px;
    @extend .font-size-30;
    color: $grey-60;
  }

  &__children {
    margin-top: 16px;
  }
}