.settings {
  &__tabs {
    border-top: 1px solid $grey-10;
  }

  &__tabs-list {
    background-color: $white;
    border-bottom: 1px solid $grey-10;
  }
}
