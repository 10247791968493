*,
*:before,
*:after {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

* {
  margin: 0;

  &:focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
}

body {
  isolation: isolate;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root,
#__next {
  height: 100%;
}

figure {
  font-size: 0;
}
