.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: sidebar;
  width: 70px;
  height: 100vh;
  padding: 24px 8px;
  background-color: $grey-135;
  transition: 300ms width;
  z-index: 3;

  &--expanded {
    align-items: flex-start;
    width: 240px;
  }

  &__figure {
    width: 100%;
  }

  &__logo-link,
  &__link {
    position: relative;
    border-radius: 3px;
    transition: 300ms all;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      border: 3px solid rgba(0, 0, 0, 0);
    }

    &:hover {
      background-color: $grey-30-overlay-8;
    }

    &:focus:after {
      border-color: $purple-60;
    }
  }

  &__logo-link {
    display: block;
    padding: 8px 10px;
    overflow: hidden;
  }

  &__logo {
    max-width: 127px;
    height: 34px;

    &--small {
      width: 34px;
      height: 34px;
    }
  }

  &__expand {
    position: absolute;
    top: 48px;
    right: 0;
    width: 24px;
    height: 24px;
    padding: 0;
    appearance: none;
    background-color: $grey-100;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 12px rgba(9, 30, 66, 0.15);
    color: $grey-40;
    text-align: center;
    transition: 300ms all;
    transform: translateX(50%);
    cursor: pointer;

    &:hover {
      width: 34px;
      background-color: $purple-60;
      color: $white;
      text-align: right;

      .sidebar__arrow {
        left: 100%;
        transform: translate(-14px, -50%);

        &--expanded {
          transform: translate(-14px, -50%) rotate(180deg);
        }
      }
    }

    &--expanded:hover {

      .sidebar__arrow {
        left: 0;
        transform: translate(10px, -50%) rotate(180deg);
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 8px;
    color: inherit;
    transform: translate(-50%, -50%);
    transition: 100ms all;

    &--expanded {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &__menu {
    display: block;
    margin: 16px 0 0;
    padding: 0 5px;
    list-style: none;
    width: 100%;

    &--expanded {
      padding: 0;
    }
  }

  &__item {
    display: block;
  }

  &__link {
    display: inline-flex;
    width: 100%;
    min-height: 40px;
    color: $white;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:hover {

      .sidebar__tooltip {
        opacity: 1;
        pointer-events: initial;
      }
    }

    &--disabled {
      color: $grey-60;
    }

    &--active {
      background-color: $grey-30-overlay-8;
    }

    &--expanded {
      justify-content: flex-start;

      &:hover {
        .sidebar__tooltip {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &__label {
    display: inline-block;
    padding-left: 46px;
    width: 0;
    @extend .font-size-30;
    opacity: 0;
    overflow: hidden;
    transition: 300ms all;

    &--expanded {
      width: auto;
      opacity: 1;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    transform: translate(-50%, -50%);

    &--reports {
      width: 18px;
      height: 15px;
    }

    &--incidents {
      width: 16px;
    }

    &--requests {
      width: 15px;
      height: 18px;
    }

    &--inventory {
      width: 20px;
    }

    &--organisations,
    &--organisation {
      width: 20px;
      height: 20px;
    }

    &--sites {
      width: 17px;
      height: 17px;
    }

    &--orders {
      width: 19px;
      height: 19px;
    }

    &--expanded {
      left: 10px;
      transform: translate(0, -50%);
    }
  }

  &__soon {
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 0 4px;
    @extend .font-size-5;
    font-weight: 600;
    background-color: $purple-60;
    border-radius: 2px;
    opacity: 0;
    color: $white;
    text-transform: uppercase;
    pointer-events: none;
    transform: translateY(-50%);
    z-index: 1;

    &--expanded {
      opacity: 1;
      transition: 300ms opacity;
      transition-delay: 100ms;
    }
  }

  &__tooltip {
    position: absolute;
    top: 50%;
    left: calc(100% + 28px);
    padding: 7px 10px;
    border-radius: 4px;
    background-color: $grey-120;
    box-shadow: 0px 2px 4px rgba(18, 28, 45, 0.1);
    @extend .font-size-10;
    font-weight: 500;
    opacity: 0;
    color: $white;
    pointer-events: none;
    transform: translateY(-50%);
    transition: 300ms all;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -28px;
      width: 28px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5px 6px 0;
      border-color: transparent $grey-120 transparent transparent;
      transform: translate(-5px, -50%);
    }
  }
}
