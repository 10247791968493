.site-name {
  display: inline-block;
  max-width: 100%;

  &__form {
    position: relative;
    display: inline-block;
    max-width: 100%;
    padding-right: 78px;
  }

  &__input-group {
    max-width: 100%;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: none;
  }

  &__input,
  &__input[readonly] {
    max-width: 100%;
    padding: 6px 0;
    border: 0;
    background-color: $white;
    color: $grey-100;
  }

  &__edit {
    position: absolute;
    top: 50%;
    right: 48px;
    appearance: none;
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: rgba(0,0,0,0);
    border-radius: 3px;
    border: 0;
    color: $grey-60;
    font-size: 0;
    cursor: pointer;
    transform: translateY(-50%);
    transition: 300ms all;

    &:hover {
      background: $grey-5;
      color: $grey-60;

      .site-name__tooltip {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &__edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
  }

  &__buttons {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    transition: 300ms all;

    &--editing {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__save,
  &__close {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    appearance: none;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $white;
    color: $grey-80;
    cursor: pointer;
    vertical-align: middle;
    transition: 300ms all;

    &:hover {
      color: $grey-80;
    }
  }

  &__save {
    border-color: $grey-20;

    &:hover {
      background: $grey-5;
    }
  }

  &__close {
    color: $grey-40;
  }

  &__save + &__close {
    margin-left: 8px;
  }

  &__save-icon,
  &__close-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__save-icon {
    width: 12px;
    height: 8px;
  }

  &__close-icon {
    width: 9px;
    height: 9px;
  }

  &__tooltip {
    position: absolute;
    top: -100%;
    padding: 2px 6px;
    background-color: $grey-90;
    box-shadow: 0px 2px 4px rgba(18, 28, 45, 0.1);
    border-radius: 3px;
    @extend .font-size-10;
    font-weight: 500;
    opacity: 0;
    color: $white;
    pointer-events: none;
    transform: translate(-50%, -20%);
    transition: 300ms all;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: -10px;
      left: 0;
      width: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 10px;
      height: 3px;
      background-image: url(/tooltip-tail.svg);
      background-size: contain;
      transform: translate(-50%, 100%);
    }
  }
}
