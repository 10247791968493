.dropdown {

  &__button {
    display: inline-block;
    appearance: none;
    padding: 6px 8px 6px 12px;
    background-color: rgba(0,0,0,0);
    border: 1px solid $grey-20;
    border-radius: 4px;
    color: $grey-80;
    font-size: 0;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      background-color: $grey-5;
    }

    &--active {
      background-color: $grey-10;
    }

    &:disabled {
      background-color: $white;
      border-color: $grey-20;
      opacity: 30%;
    }
  }

  &__text,
  &__wrap {
    display: inline-block;
    vertical-align: middle;
  }

  &__text {
    @extend .font-size-30;
  }

  &__text + &__wrap {
    margin-left: 4px;
  }

  &__wrap {
    position: relative;
    width: 20px;
    height: 20px;
    transition: 300ms all;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 5px;
    color: $grey-80;
    transform: translate(-50%, -50%);
    transition: 300ms all;

    &--active {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &__content {
    position: relative;
    min-width: 260px;
    padding: 5px 0;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0px 1px 8px 0px #56426026;
    z-index: 3;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
  }

  &__item {
    display: block;
  }

  &__option {
    display: block;
    width: 100%;
    appearance: none;
    padding: 10px 12px;
    border: 0;
    background-color: rgba(0,0,0,0);
    @extend .font-size-30;
    text-align: left;
    color: $grey-135;
    transition: 300ms all;
    cursor: pointer;

    &:disabled {
      cursor: initial;
      opacity: 0.2;
    }

    &:hover {
      background-color: $off-white;
    }
  }

  &__dot,
  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: $green-100;
    overflow: hidden;

    &--black {
      background-color: $grey-100;
    }

    &--blue {
      background-color: $blue-80;
    }

    &--red {
      background-color: $red-60;
    }

    &--purple {
      background-color: $purple-60;
    }
  }
  &__dot + &__label {
    margin-left: 10px;
  }
}