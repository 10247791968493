.single-customer {
  &__main {
    height: auto;
    min-height: calc(100vh - 196px);
  }

  &__summary-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__summary-title {
    @extend .font-size-40;
    color: $grey-135;
    margin-bottom: 4px;
  }

  &__summary-address {
    @extend .font-size-20;
    color: $grey-60;
  }

  &__details {
    padding: 27px 20px;
  }

  &__site {
    display: block;
    color: $purple-60;
    margin-bottom: 18px;
    margin-top: 9px;
    @extend .font-size-25;
    text-decoration: none;
  }

  &__users {
    height: auto;
  }

  &__tabs-wrapper {
    align-self: flex-start;
  }

  &__tabs-upper {
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__tab-list {
    margin-top: 0px;
  }

  &__tab-button {
    padding: 20px 0 18px;
    font-size: 16px;
  }

  &__table {
    background-color: $white;
  }

  &__table-name {
    display: flex;
    align-items: center;
    @extend .table__text;
  }

  &__table-avatar {
    margin-right: 11px;

    &--inactive {
      background-color: $grey-50;
    }
  }

  &__new-user {
    display: inline-flex;
    align-items: center;
  }

  &__new-user-plus {
    margin-right: 8px;
  }

  &__remove-user-title {
    @extend .font-size-50;
    font-weight: 500;
    color: $grey-135;
    margin-bottom: 8px;
  }

  &__remove-user-desc {
    @extend .font-size-30;
    color: $grey-60;
    margin-bottom: 30px;
    display: inline-block;
  }

  &__remove-user-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  &__deactivate-title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 9px;
    }
  }

  &__deactivate-desc {
    ul {
      padding: 0px 20px;

      li {
        margin-top: 20px;
      }
    }
  }

  &__inactive-text {
    color: $grey-50;
  }

  &__destructive-action {
    color: $orange-60;
  }

  &__constructive-action {
    color: $purple-60;
  }
}
