.vrf-option {
  position: relative;
  align-items: center;
  flex-direction: row;
  display: flex;
  gap: 20px;
  box-shadow: 0 4px 6px -1px rgb(235, 233, 233), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 15px;
  background: $white;
  cursor: pointer;

  &__form {
    flex-direction: column;
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &__delete {
    position: absolute;
    top: 5px;
    right: 5px;
    transition: 0.3s ease;
    border: 1px solid $red-60;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    padding: 8px;
    background-color: $white;
    color: $red-60;

    &:hover {
      background-color: $red-60;
      color: $white;
    }
  }

  &__inputs {
    flex-direction: column;
    display: flex;
    gap: 15px;
    width: 100%;
    text-align: left;
  }

  &__edit-icon {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    color: $purple-60;
  }

  &--selected {
    border-color: $purple-60;
  }

  &--editing {
    padding-top: 20px;
  }

  &__icon {
    margin: auto;
    width: 20px;
    height: 20px;
    color: $purple-60;
  }

  &__label {
    @extend .font-size-30;
    flex: 1;
    margin: 15px;
    text-align: left;
  }

  &__actions {
    display: flex;
    gap: 10px;
    width: 100%;

    button {
      flex: 1;
    }
  }
}
