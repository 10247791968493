.sites {
  &__cell,
  &__cell:first-of-type,
  &__cell:last-of-type {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $purple-60;
    border-radius: 1000px;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: $white;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
  }
}