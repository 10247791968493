.subnet-list {
  flex-direction: column;
  display: flex;
  gap: 20px;

  &__validation {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 15px;
  }

  &__icon {
    width: 14px;
    height: 14px;
    color: $purple-60;

    &--cross {
      width: 12px;
      height: 12px;
      color: $red-60;
    }
  }

  &__validation-message {
    @extend .font-size-30;
  }

  &__add-subnet-btn {
    margin-top: 10px;
    border-radius: 50px;
    width: 100%;
  }

  &__delete-subnet {
    position: absolute;
    top: -10px;
    right: 20px;
    align-items: center;
    display: flex;
    transition: 0.3s ease;
    margin-top: 30px;
    border: 1px solid $red-60;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    background: transparent;
    cursor: pointer;
    color: $red-60;

    &:hover {
      background-color: $red-60;
      color: $white;
    }
  }

  &__subnet {
    position: relative;
    background-color: $white;
    border: 1px solid $purple-60;
    border-color: $grey-10;
    border-radius: 16px;
    box-shadow: 0px 1px 2px 0px rgba(55, 29, 66, 0.05);
    padding: 20px;

    &[open] {
      border-color: $purple-60;
      cursor: default;

      .subnet-list__edit-icon {
        display: none;
      }
    }
  }

  &__subnet-summary {
    justify-content: space-between;
    display: flex;
    cursor: pointer;
    list-style: none;
  }

  &__label {
    display: flex;
    gap: 10px;
  }

  &__edit-icon {
    width: 20px;
    height: 20px;
    color: $purple-60;
  }
}
