.comment-form {
  min-height: 124px;
  padding: 16px 24px;
  border-top: 1px solid $grey-10;
  background-color: $white;

  &--internal {
    background-color: $yellow-20;
  }

  &__form {
    position: relative;
    padding-right: 290px;
  }

  &__input {
    width: 100%;
    min-height: 104px;
    border: 0;
    padding: 0;
    @extend .font-size-30;
    appearance: none;
    background-color: rgba(0,0,0,0);
    resize: none;
    color: $grey-135;

    &::placeholder {
      color: $grey-40;
      opacity: 1;
      font-style: italic;
    }
  }

  &__wrap {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__attach,
  &__submit {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: 300ms all;
    vertical-align: middle;
  }

  &__attach {
    width: 20px;
    height: 20px;
    color: $grey-70;
    font-size: 0;

    &:disabled,
    &--disabled {
      color: $grey-30;
      cursor: not-allowed;

      &:hover {
        color: $grey-30;
      }
    }

    &:hover {
      color: $grey-135;
    }
  }

  &__attach + &__submit {
    margin-left: 18px;
  }

  &__button {
    margin: 0 8px;
  }

  &__submit {
    display: inline-block;
    appearance: none;
    padding: 6px 8px;
    border: 1px solid $purple-60;
    border-radius: 2px;
    background-color: $purple-60;
    font-size: 0;
    color: $white;
    overflow: hidden;

    &:hover {
      background-color: $purple-80;
      border-color: $purple-80;
    }

    &:disabled,
    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: $purple-80;
        border-color: $purple-80;
      }
    }
  }

  &__label,
  &__send {
    display: inline-block;
    vertical-align: middle;
  }

  &__label + &__send {
    margin-left: 8px;
  }

  &__label {
    @extend .font-size-30;
  }

  &__attachment-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
  }

  &__send {
    width: 13px;
    height: 13px;
  }

  &__hidden {
    display: none;
  }

  &__attachments {
    padding-bottom: 16px;
  }

  &__attachment {
    position: relative;
    max-width: 180px;
    width: 100%;
    margin: 0 0 0 auto;
    padding: 8px 12px 8px 40px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $grey-10;
    box-shadow: 0 2px 4px 0 rgba(18, 28, 45, 0.1);
    text-align: left;
  }

  &__spinner,
  &__file-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  &__file-icon {
    width: 12px;
    height: 12px;
    color: $grey-70;
  }

  &__name {
    display: block;
    max-width: 9vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    @extend .font-size-30;
    font-weight: 500;
    color: $purple-60;
    overflow: hidden;
  }

  &__size {
    display: block;
    @extend .font-size-20;
    color: $grey-70;
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 13px;
    height: 13px;
    padding: 0;
    appearance: none;
    border-radius: 50%;
    border: 0;
    background-color: $grey-70;
    color: $white;
    cursor: pointer;
    transform: translate(7px, -7px);
    transition: 300ms all;

    &:hover {
      background-color: $grey-120;
    }
  }

  &__remove-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
  }

  &__loading {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $grey-30;
    border-left: 1px solid $grey-135;
    animation: spin 0.8s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}