.layout {
  max-width: 100%;
  height: 100vh;
  background-color: $off-white;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidebar navbar'
    'sidebar main';

  &__content {
    grid-area: main;
    height: calc(100vh - 72px);
    overflow: auto;
  }
}
