.avatar {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: $purple-60;
  overflow: hidden;

  &--medium {
    width: 40px;
    height: 40px;
  }

  &--small {
    width: 24px;
    height: 24px;
  }

  &--xs {
    width: 16px;
    height: 16px;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__initials {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: $white;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    display: inline-block;

    &--medium {
      font-size: 14px;
    }

    &--small {
      font-size: 10px;
    }

    &--xs {
      font-size: 8px;
    }
  }
}
