.add-on {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  gap: 15px;
  border: 1px solid $purple-60;
  border-radius: 16px;
  padding: 15px 20px;
  max-width: 50%;

  &__type {
    @extend .font-size-30;
  }

  &__details {
    flex-direction: column;
    display: flex;
    gap: 15px;
  }

  &__label {
    @extend .font-size-10;
    line-height: 1.2;
    color: $grey-80;
  }

  &__value {
    @extend .font-size-10;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &--true {
      width: 15px;
      height: 15px;
      color: $green-80;
    }

    &--false {
      width: 15px;
      height: 15px;
      color: $red-60;
    }
  }

  &__section {
    align-items: center;
    flex-direction: row;
    display: flex;
    gap: 15px;
  }
}
