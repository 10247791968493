.email-modal {
  max-height: calc(100vh - 112px);
  max-width: 700px;
  margin: 0 auto;

  &__wrap {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 172px);
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__avatar,
  &__name {
    display: inline-block;
    vertical-align: middle;
  }

  &__avatar + &__name {
    margin-left: 8px;
  }

  &__name,
  &__date {
    @extend .font-size-20;
    color: $grey-70;
  }

  &__row {
    margin-top: 1px;
  }

  &__info + &__row {
    margin-top: 20px;
  }

  &__col--left {
    text-align: right;
    max-width: 65px;
    flex-basis: 65px;
  }

  &__col--right {
    max-width: calc(100% - 65px);
    flex-basis: calc(100% - 65px);
  }

  &__label,
  &__value {
    @extend .font-size-30;
    color: $grey-135;

    &--hidden {
      opacity: 0;
    }
  }

  &__label {
    font-weight: 500;
  }

  &__inner {
    width: 100%;
    margin-top: 30px;
    background-color: $off-white;
    border: 1px solid $grey-10;
    border-radius: 8px;
    overflow: auto;
  }

  &__loading,
  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
  
  &__content {
    padding: 20px;
    @extend .font-size-30;
    color: $grey-135;

    table {
      table-layout: fixed;
      max-width: 100%;

      &[width="540"] {
         table-layout: auto;
      }
    }
  }

  &__footer {
    margin-top: 20px;
    text-align: right;
  }
}