.not-found {
  &__authenticated-page {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 200px;
  }

  &__unauthenticated {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $off-white;
  }

  &__wrapper {
    display: flex;
    width: 90%;
    max-width: 850px;
  }

  &__content {
    margin-right: 40px;
    flex: 1;
  }

  &__title {
    @extend .font-size-70;
    font-weight: 600;
    color: $grey-135;
    margin-bottom: 10px;
  }

  &__desc {
    @extend .font-size-30;
    color: $grey-60;
    margin-bottom: 40px;
  }
}
