.signal-strength {
  min-width: 95px;

  &__bars,
  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &__bar {
    display: inline-block;
    margin-left: 2px;
    height: 5px;
    width: 2px;
    border-radius: 0.5px;
    vertical-align: baseline;
    background-color: $grey-10;
    overflow: hidden;

    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-child(2) {
      height: 8px;
    }

    &:nth-child(3) {
      height: 11px;
    }

    &:nth-child(4) {
      height: 14px;
    }

    &--poor:nth-child(1) {
      background-color: $orange-60;
    }

    &--fair {
      &:nth-child(1),
      &:nth-child(2) {
        background-color: $yellow-60;
      }
    }

    &--good {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        background-color: $green-60;
      }
    }

    &--excellent {
      background-color: $green-60;
    }
  }

  &__label {
    margin-left: 13px;
    @extend .font-size-25;
    color: $grey-100;
    text-transform: capitalize;
  }
}