.select-group {
  width: 100%;
  font-size: 0;

  &__label-wrap + &__field-wrap {
    margin-top: 8px;
  }

  &__field-wrap {
    position: relative;
  }

  &__field {
    position: relative;
    width: 100%;
    padding: 7px 32px 7px 8px;
    appearance: none;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $grey-10;
    @extend .font-size-20;
    color: $grey-135;

    &:focus {
      border-color: $purple-60;
    }

    &::placeholder {
      color: $grey-40;
      opacity: 1;
    }

    &:disabled {
      background-color: $off-white;
      border-color: $grey-5;
      color: $grey-30;
    }

    &--large {
      padding: 10px 32px 10px 12px;
      @extend .font-size-30;
    }

    &--error {
      border-color: $orange-60;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 13px;
    width: 7px;
    height: 7px;
    color: $grey-60;
    transform: translateY(-50%);
    pointer-events: none;

    &--large {
      right: 18px;
      width: 9px;
      height: 9px;
    }
  }

  &__error {
    display: block;
    margin-top: 4px;
    @extend .font-size-10;
    color: $orange-60;

    &--large {
      @extend .font-size-20;
    }
  }

  &__asterisk {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }
}
