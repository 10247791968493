.field-group {
  box-shadow: 0 4px 2px -2px rgb(235, 233, 233);
  border: 1px solid #EAE7EB;
  border-radius: 15px;
  width: 270px;
  padding: 20px 25px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  &__field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__label {
    @extend .font-size-30;
    color: $grey-80;
  }

  &__description {
    @extend .font-size-20;
  }
}
