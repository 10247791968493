.raise-modal {

  &__content {
    overflow-y: auto;
    max-width: 600px;
  }

  &__content .dialog__inner {
    min-height: 300px;
  }

  &__heading {
    @extend .font-size-50;
    font-weight: 500;
    color: $grey-135;
  }

  &__subheading {
    margin: 8px 0 0;
    @extend .font-size-30;
    color: 400;
    color: $grey-60;
  }

  &__required {
    margin: 0 0 20px;
  }

  &__asterisk,
  &__info {
    display: inline-block;
    vertical-align: top;
    @extend .font-size-30;
    color: $grey-60;
  }

  &__asterisk {
    margin-right: 6px;
    color: $orange-60;
  }

  &__upper,
  &__message {
    margin-top: 30px;
  }

  &__selection {
    margin-top: 8px;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 17px;
    width: 9px;
    height: 9px;
    transform: translateY(-50%);
    color: $grey-60;
  }

  &__row,
  &__lower {
    margin-top: 20px;
  }

  &__row--no-padding {
    padding: 0;
    margin-right: 0;
    margin-left: 0;
  }

  &__form-row + &__form-row {
    margin-top: 30px;
  }

  &__form-row--no-margin,
  &__form-row + &__form-row--no-margin {
    margin-top: 0;
  }

  &__loading,
  &__form {
    margin-top: 40px;
  }

  &__loading {
    display: flex;
    min-height: 160px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__label + &__search-select {
    margin-top: 8px;
  }

  &__selection + &__label {
    margin-top: 20px;
  }

  &__col {
    margin-top: 20px;

    &--no-margin,
    &--top {
      margin-top: 0;
    }

    &--large-margin {
      margin-top: 40px;
    }
  }

  &__footer {
    margin-top: 40px;
    text-align: right;
  }

  &__button + &__button {
    margin-left: 12px;
  }

  &__button--submit {
    min-width: 120px;
  }

  &__fieldset {
    padding: 20px;
    background-color: $off-white;
    border: 1px solid $grey-10;
    border-radius: 12px;
    overflow: hidden; 
  }

  &__title {
    @extend .font-size-40;
    color: $grey-135;
  }

  &__inner-row {
    margin-right: -2.5px;
    margin-left: -2.5px;
    padding: 0;

    .raise-modal__col {
      padding: 0 2.5px;
    }
  }

  &__inner-row + &__inner-row,
  &__fieldset +  {
    margin-top: 40px;
  }

  &__add-wrap {
    margin-top: 20px;
    text-align: right;
  }

  &__add {
    background-color: $white;
  }

  &__file {
    background-color: $white;
  }

  &__star {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }
}