.comment {
  margin: 28px auto 0 0;
  max-width: 700px;

  &--wide {
    max-width: 100%;
  }

  &--admin {
    margin-right: 0;
    margin-left: auto;
  }

  &__content,
  &__attachment {
    padding: 12px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $grey-10;

    &--internal {
      background-color: $yellow-20;
    }
  }

  &__content {
    @extend .font-size-30;
    color: $grey-135;
    overflow: auto;

    strong {
      font-weight: 500;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }

  &__attachments {
    list-style-type: none;
    padding-left: 0;
  }

  &__item {
    display: block;
  }

  &__attachment {
    position: relative;
    padding-left: 40px;

    &:hover {

      .comment__link-text {
        color: $purple-90;

        &:after {
          border-color: $purple-90;
        }
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    text-decoration: none;
    z-index: 1;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 14px;
    width: 14px;
    height: 14px;
    color: $grey-70;
    transform: translateY(-50%);
  }

  &__link-text {
    position: relative;
    display: inline-block;
    @extend .font-size-30;
    color: $purple-60;
    transition: 300ms all;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 1px;
      left: 0;
      border-bottom: 1px solid $purple-60;transition: 300ms all;
    }
  }

  &__filesize {
    @extend .font-size-20;
    color: $grey-70;
  }

  &__avatar,
  &__name,
  &__internal {
    display: inline-block;
    vertical-align: middle;
  }

  &__avatar + &__name,
  &__avatar + &__internal,
  &__name + &__internal {
    margin-left: 8px;
  }

  &__name,
  &__date {
    @extend .font-size-20;
    color: $grey-70;
  }

  &__internal {
    padding: 0 4px;
    background-color: $yellow-80;
    border-radius: 2px;
    @extend .font-size-5;
    font-weight: 500;
    text-transform: uppercase;
    color: $grey-135;
    overflow: hidden;
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
  }

  &__row {
    margin-top: 1px;

    &:first-child {
      margin-top: 0;
    }

    &--details {
      margin-top: 6px;
    }
  }

  &__col--left {
    text-align: right;
    max-width: 65px;
    flex-basis: 65px;
  }

  &__col--right {
    max-width: calc(100% - 65px);
    flex-basis: calc(100% - 65px);
  }

  &__label,
  &__value {
    @extend .font-size-30;
    color: $grey-135;

    &--hidden {
      opacity: 0;
    }
  }

  &__label {
    font-weight: 500;
  }

  &__button {
    display: inline-block;
    appearance: none;
    padding: 0;
    background-color: rgba(0,0,0,0);
    border: 0;
    @extend .font-size-25;
    color: $purple-60;
    text-decoration: underline;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      color: $purple-90;
    }
  }
}