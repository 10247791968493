.actions {
  &__trigger {
    appearance: none;
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    transition: 0.3s background-color ease;
    padding: 0px;

    &:hover {
      background-color: $grey-10;
    }

    &:focus,
    &[data-state='open'] {
      outline: 1px solid $purple-60;
    }
  }

  &__content {
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0px 1px 8px rgba(86, 66, 96, 0.15);
    min-width: 240px;
    padding: 5px 0;
    z-index: 1;
  }

  &__item {
    padding: 8px;
    color: $grey-135;
    @extend .font-size-20;
    transition: 0.3s ease;
    display: block;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: $grey-5;
    }
  }
}
