.priority-dot {
  position: relative;
  display: inline-block;
  padding-left: 18px;

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: $green-60;
    transform: translateY(-50%);

    &--medium {
      background-color: $yellow-60;
    }

    &--high {
      background-color: $orange-60;
    }

    &--critical {
      background-color: $maroon-60;
    }
  }

  &__label {
    @extend .font-size-25;
    color: $grey-100;
  }
}