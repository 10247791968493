.toggle-group {

  &__label + &__wrap {
    margin-top: 8px;
  }

  &__wrap {
    align-items: center;
    display: flex;
    min-height: 30px;

    &--large {
      min-height: 40px;
    }
  }

  &__root,
  &__title {
    display: inline-block;
    vertical-align: middle;
  }

  &__root {
    width: 36px;
    height: 20px;
    padding: 2px;
    border-radius: 12px;
    border: 0;
    appearance: none;
    background-color: $grey-5;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &[data-state='checked'] {
      background-color: $green-60;
    }
  }

  &__thumb {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    transition: 300ms all;
    
    &[data-state='checked'] {
      transform: translateX(16px);
    }
  }

  &__title {
    margin-left: 8px;
    @extend .font-size-30;
    color: $grey-100;
  }

  &__asterisk {
    display: inline-block;
    margin-left: 4px;
    color: $orange-60;
    vertical-align: top;
  }
}