.item-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;

  &__item {
    margin-top: 4px;
    padding: 7px 0 16px;

    &:first-child {
      margin-top: 0;
    }

    &--edit {
      padding-bottom: 9px;
    }
  }

  &__heading {
    margin-bottom: 9px;
    @extend .font-size-10;
    font-weight: 600;
    text-transform: uppercase;
    color: $grey-70;

    &--edit {
      margin-bottom: 2px;
    }
  }

  &__value {
    @extend .font-size-25;
    color: $grey-100;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0;
    color: $purple-60;
    text-decoration: none;
    transition: 300ms all;

    &:hover {
      color: $purple-90;
    }
  }

  &__link-label {
    @extend .font-size-25;
  }

  &__link-icon {
    width: 9px;
    height: 9px;
  }

  &__wrap {
    position: relative;
    display: inline-block;
    font-size: 0;
  }

  &__checkbox {
    appearance: none;
    width: 14px;
    height: 14px;
    background-color: $grey-20;
    border-radius: 4px;
    border: 0;
    color: $grey-80;
  }

  &__checkbox-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
  }

  &__checkbox-label {
    font-size: 0;
    line-height: 0;
  }
}