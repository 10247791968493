body {
  color: $grey-60;
  font-weight: 400;
}

// Set up classes for all font-sizes
@each $class, $attributes in $typography {
  .#{"font-size-" + $class} {
    @include font-attributes($attributes);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: $grey-135;
}

label, .label {
  @extend .font-size-10;
  font-weight: 600;
  color: $grey-80;
  text-transform: uppercase;

  &--large {
    @extend .font-size-20;
  }
}

.link {
  position: relative;
  display: inline-block;
  @extend .font-size-10;
  font-weight: 500;
  color: $purple-60;
  cursor: pointer;
  text-decoration: none;
  transition: 300ms all;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    height: 1px;
    background-color: $purple-60;
    transition: 300ms all;
  }

  &--large {
    @extend .font-size-20;
  }

  &:hover {
    color: $purple-90;

    &:after {
      background-color: $purple-90;
    }
  }
}