.device-status {
  position: relative;

  &__label {
    @extend .font-size-5;
    display: inline-block;
    border-radius: 20px;
    width: max-content;
    padding: 1px 4px 2px;
    background-color: $grey-40-overlay-16;
    font-weight: 600;
    text-transform: capitalize;
    vertical-align: middle;
    color: $grey-100;

    &--large {
      @extend .font-size-10;
      padding: 1px 6px 2px;
    }

    &--online {
      background-color: $green-60-overlay-8;
      color: $green-60;
    }

    &--offline {
      background-color: $orange-60-overlay-8;
      color: $orange-60;
    }

    &--upgrade {
      background-color: $burple-60-overlay-8;
      color: $burple-60;
    }
  }

  &__tooltip {
    @extend .font-size-10;
    position: absolute;
    visibility: hidden;
    z-index: 999;
    top: 0.15rem;
    left: 5.2rem;
    border-radius: 5px;
    box-shadow: 0px 1px 8px rgba(86, 66, 96, 0.15);
    width: 11rem;
    padding: 1px 4px 2px;
    background-color: $white;
    vertical-align: middle;
    color: $grey-100;

    &.device-status__tooltip--small {
      left: 4.2rem;
    }

    &--offline {
      left: 3rem;

      &.device-status__tooltip--small {
        left: 2.4rem;
      }
    }

    &--upgrade {
      left: 3.4rem;
      width: 14rem;

      &.device-status__tooltip--small {
        left: 2.8rem;
      }
    }
  }

  [aria-describedby]:hover + [role="tooltip"],
  [aria-describedby]:focus + [role="tooltip"] {
    visibility: visible;
  }
}
