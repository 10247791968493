.impersonation-banner {
  position: fixed;
  left: 50%;
  bottom: 20px;
  padding: 8px 48px;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  background-color: $purple-60;
  border-radius: 6px;
  z-index: 1;

  &__text {
    @extend .font-size-30;

    display: flex;
    color: $white;
    margin-right: 16px;
  }

  &__icon {
    height: 20px;

    path {
      fill: $white;
    }
  }

  &__button {
    color: $white;
    border-color: $white;
    box-shadow: 0px 1px 2px 0px rgba(55, 29, 66, 0.05);
  }
}
