.btn {
  display: inline-block;
  min-width: 42px;
  border-radius: 5px;
  padding: 10px 12px;
  border: 1px solid $purple-60;
  background-color: $purple-60;
  font-weight: 500;
  @extend .font-size-30;
  color: $white;
  cursor: pointer;
  transition: 300ms all;

  &:focus {
    background-color: $purple-80;
    border-color: $purple-80;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $purple-90;
    border-color: $purple-90;
  }

  &__label,
  &__icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__icon+&__label,
  &__label+&__icon {
    margin-left: 6px;
  }

  &__icon {
    width: 14px;
    height: 14px;

    &--arrow {
      width: 10px;
      height: 8px;
    }

    &--search {
      width: 12px;
      height: 12px;
    }

    &--loading {
      width: 16px;
      height: 16px;
      animation: rotating 1s infinite linear;
    }

    &--tick {
      width: 12px;
      height: 8px;
    }
  }

  // Button colour modifiers
  &--secondary {
    background-color: $grey-95;
    border-color: $grey-95;

    &:hover {
      background-color: $grey-120;
      border-color: $grey-120;
    }

    &:focus {
      background-color: $grey-135;
      border-color: $grey-135;
    }
  }

  &--tertiary {
    background-color: $orange-60;
    border-color: $orange-60;

    &:hover {
      background-color: $orange-80;
      border-color: $orange-80;
    }

    &:focus {
      background-color: $orange-90;
      border-color: $orange-90;
    }
  }

  &--primary-outline {
    background-color: rgba(0, 0, 0, 0);
    border-color: $purple-60;
    color: $purple-60;

    &:hover {
      background-color: $purple-60-overlay-8;
    }

    &:focus {
      background-color: $purple-60-overlay-16;
    }
  }

  &--secondary-outline {
    background-color: rgba(0, 0, 0, 0);
    border-color: $grey-20;
    color: $grey-80;

    &:hover {
      background-color: $grey-5;
      border-color: $grey-20;
    }

    &:focus {
      border-color: $grey-30;
      background-color: $grey-5;
    }
  }

  &--tertiary-outline {
    background-color: $white;
    border-color: $orange-60;
    color: $orange-60;

    &:hover,
    &:focus {
      border-color: $orange-60;
      background-color: $orange-60;
      color: $white;
    }
  }

  &--success-outline {
    background-color: rgba(0, 0, 0, 0);
    border-color: $green-20;
    color: $green-80;

    &:hover,
    &:focus {
      border-color: $green-20;
      background-color: $green-60-overlay-8;
    }
  }

  &--small {
    @extend .font-size-20;
    padding: 6px 8px;
  }
}
