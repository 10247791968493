.checkbox-group {
  text-align: left;

  &__label-wrap + &__field-wrap {
    margin-top: 8px;
  }

  &__label {
    display: inline-block;
    vertical-align: middle;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__field {
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: 1px solid $grey-20;
    background-color: $white;
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
    cursor: pointer;
    transition: 300ms all;

    &:checked {
      background-color: $purple-60;
      border-color: $purple-60;
      background-image: url('/check-white.svg');
    }
  }

  &__content {
    margin-left: 8px;
    @extend .font-size-30;
    font-weight: 400;
    text-transform: none;
    color: $grey-135;
    cursor: pointer;
  }

  &__error {
    display: block;
    margin-top: 10px;
    @extend .font-size-10;
    color: $orange-60;

    &--large {
      @extend .font-size-20;
    }
  }

  &__tooltip {
    max-width: 252px;
  }
}