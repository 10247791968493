.network-summary {

  // React D3 tree styles
  .rd3t-tree-container {
    position: relative;
    z-index: 1;
  }

  .rd3t-link {
    stroke: $grey-30;
    stroke-dasharray: 4;
    stroke-width: 2;
  }

  &__wrap,
  &__alert {
    position: relative;
    width: 100%;
    height: calc(100vh - 126px);
    background-color: $grey-5;
  }

  &__alert {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 30px;
  }

  &__controls {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $grey-10;
    overflow: hidden;
    z-index: 2;
  }

  &__button {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    font-size: 0;
    appearance: none;
    padding: 0;
    background-color: $white;
    border: 0;
    color: $grey-100;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      background-color: $grey-5;
    }
  }

  &__button + &__button:after {
    content: '';
    position: absolute;
    top: -0.5px;
    left: 50%;
    width: 24px;
    border-top: 1px solid $grey-10;
    transform: translateX(-50%);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
  }

  &__icon--minus {
    width: 16px;
    height: 2px;
  }
}