.dashboard {

  &__tabs {
    border-top: 1px solid $grey-10;
  }

  &__list {
    background-color: $white;
    border-bottom: 1px solid $grey-10;
  }

  &__col {
    margin-top: 20px;
  }

  &__chart,
  &__empty-chart {
    position: relative;
    max-width: 300px;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: calc(50%);
    min-width: 44px;
    margin-left: -48px;
    @extend .font-size-70;
    font-weight: 700;
    color: $grey-135;
    text-align: center;
    transform: translate(-50%, -50%);

    &--small {
      margin-left: -45px;
    }
  }

  &__tab {
    padding-bottom: 40px;
  }

  &__upgrade {
    padding: 21px 0 16px;
    text-align: center;
  }
}