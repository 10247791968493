.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &--bottom {
    flex-direction: column;
  }

  &--inline-block {
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
  }

  &__circle {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;

    &--14 {
      width: 14px;
      height: 14px;
    }

    &--16 {
      width: 16px;
      height: 16px;
    }

    &--20 {
      width: 20px;
      height: 20px;
    }

    &--24 {
      width: 24px;
      height: 24px;
    }

    &--48 {
      width: 48px;
      height: 48px;
    }

    &--72 {
      width: 72px;
      height: 72px;
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 32px;
    height: 32px;
    border: 5px solid;
    border-radius: 50%;
    box-sizing: border-box;
    animation: circle-spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $grey-20 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }

    &--14 {
      width: 14px;
      height: 14px;
      border-width: 2px;
    }

    &--16 {
      width: 16px;
      height: 16px;
      border-width: 2px;
    }

    &--20 {
      width: 20px;
      height: 20px;
      border-width: 2.5px;
    }

    &--24 {
      width: 24px;
      height: 24px;
      border-width: 3px;
    }

    &--48 {
      width: 48px;
      height: 48px;
      border-width: 7px;
    }

    &--72 {
      width: 72px;
      height: 72px;
      border-width: 8px;
    }

    &--white {
      border-color: $white transparent transparent transparent;
    }

    &--black {
      border-color: $grey-80 transparent transparent transparent;
    }
  }

  &__text {
    display: inline-block;
    margin: 0 0 0 8px;
    @extend .font-size-30;
    color: $grey-60;
    vertical-align: middle;

    &--bottom {
      margin: 8px auto 0;
    }

    &--white {
      color: $white;
    }
  }
}

@keyframes circle-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
